import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { Loader } from 'components/Loader';

import { microToMacro } from 'utils';
import { createMission } from 'api/missions';
import { MissionsContext, prepareMission } from 'contexts/Missions';

const numberFields = ['max_pool_size', 'feePercent', 'maxVestingPercent', 'expectedRewards'];
const defaultFormValues = {
	title: '',
	gameType: '',
	max_pool_size: '',
	feePercent: '',
	maxVestingPercent: '',
	endDate: Date.now(),
	expectedRewards: '',
	expectedRewardsCoin: '',
	description: '',
};

export const ApproveMissionModal = ({ isOpen, onClose, missionCardProps }) => {
	const wallet = useAnchorWallet();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState(defaultFormValues);
	const { setMissionPools, setShouldRefresh: setShouldRefreshMission } = useContext(MissionsContext);

	const handleChange = useCallback(event => {
		setFormValues(formValues => ({
			...formValues,
			[event.target.name]: numberFields.includes(event.target.name)
				? // NOTE: This RegEx is for filtering out non numeric values.
				  event.target.value.replace(/\D/g, '')
				: event.target.value,
		}));
	}, []);

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);

			try {
				console.log(formValues)
				const mission = await createMission({
					id: missionCardProps.id,
					description: formValues.description,
					endDate: new Date(formValues.endDate).getTime(),
					expectedRewards: microToMacro(parseInt(formValues.expectedRewards)),
					feePercent: formValues.feePercent,
					gameType: formValues.gameType,
					maxPoolSize: microToMacro(parseInt(formValues.max_pool_size)),
					maxVestingPercent: formValues.maxVestingPercent,
					rewardCoinType: formValues.rewardCoinType,
					title: formValues.title,
					defaultImage: formValues['game-logo'],
					game: formValues.game,
					images: [formValues['gallery-image-1'], formValues['gallery-image-2'], formValues['gallery-image-3']],
					wallet,
				});

				setMissionPools(missionPools => [prepareMission(mission), ...missionPools]);
				setShouldRefreshMission(shouldRefresh => !shouldRefresh);

				toast.success(`Mission "${mission.title}" created successfully!`);
			} catch (error) {
				toast.error(`Error during mission creation: ${error.message}`);
				console.error(error);
			} finally {
				setIsLoading(false);
				setFormValues(defaultFormValues);
				onClose();
			}
		},
		[formValues, onClose, setMissionPools, setShouldRefreshMission, wallet, missionCardProps]
	);

	useEffect(() => {
		if (!isOpen) {
			if(missionCardProps == null)
				setFormValues(defaultFormValues);
			else{
				setFormValues(missionCardProps)
			}
		}else{
			setFormValues(missionCardProps)
		}
	}, [isOpen, missionCardProps]);

	return (
		<div id="approve-mission" className="overlay global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose} disabled={isLoading}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={() => navigate(-1)}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					{/* FORM */}
					<form id="custom-form create-mission-form" className="row main spaced" onSubmit={handleSubmit}>
						<div className="modal-title">
							<div className="row title">
								<h3>Approve Mission</h3>
							</div>
						</div>
						<div className="form-field">
							<label htmlFor="title">Mission Title</label>
							<input
								type="text"
								name="title"
								id="title"
								placeholder="Enter here"
								required
								onChange={handleChange}
								defaultValue={formValues.title}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="game-type">Game Type</label>
							<select className="form-select" required onChange={handleChange} value={formValues.gameType} name="game-type" id="game-type">
								<option value="">Select a Game</option>
								<option value="Star Atlas">Star Atlas</option>
								<option value="Illuvium">Illuvium</option>
								<option value="Mavia">Heroes of Mavia</option>
							</select>
						</div>
						<div className="form-field">
							<label htmlFor="max_pool_size">Max Pool Size</label>
							<input
								type="text"
								name="max_pool_size"
								id="max_pool_size"
								placeholder="0"
								pattern="^[1-9][0-9]*$"
								required
								onChange={handleChange}
								defaultValue={formValues.max_pool_size}
							/>
						</div>
						<div className="form-field fee-percent">
							<label htmlFor="fee-percent">Fee Percent</label>
							<input
								type="text"
								name="feePercent"
								id="feePercent"
								placeholder="0"
								required
								onChange={handleChange}
								defaultValue={formValues.feePercent}
							/>
						</div>
						<div className="form-field max-vesting-percent">
							<label htmlFor="maxVestingPercent">Max Vesting Percent</label>
							<input
								type="text"
								name="maxVestingPercent"
								id="maxVestingPercent"
								placeholder="0"
								required
								onChange={handleChange}
								value={formValues.maxVestingPercent}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="end_date">End Date</label>
							<input
								type="datetime-local"
								name="end_date"
								id="end_date"
								onChange={handleChange}
								value={new Date(new Date(formValues?.endDate * 1000).toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="expectedRewards">Expected Rewards</label>
							<input
								type="text"
								name="expectedRewards"
								id="expectedRewards"
								placeholder="0"
								required
								onChange={handleChange}
								defaultValue={formValues.expectedRewards}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="expectedRewardsCoin">Reward Coin Type</label>
							<input
								type="text"
								name="expectedRewardsCoin"
								id="expectedRewardsCoin"
								placeholder="Enter here"
								required
								onChange={handleChange}
								defaultValue={formValues.expectedRewardsCoin}
							/>
						</div>
						<div className="form-field textarea">
							<label htmlFor="description">Mission Description</label>
							<textarea
								name="description"
								id="description"
								placeholder="Enter here"
								required
								onChange={handleChange}
								defaultValue={formValues.description}
							/>
						</div>
						<button type="submit" className="cta-btn large create-mission-button" disabled={isLoading}>
							{isLoading && <Loader />}
							<span>{isLoading ? 'Please wait...' : 'Submit'}</span>
						</button>
					</form>
					{/* END FORM */}
				</div>
			</div>
		</div>
	);
};
