import { useLocalStorage } from 'usehooks-ts';
import { useCallback, useEffect, useState } from 'react';

import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

export const DefaultAtaInput = ({
	checkboxLabel = 'Use your default ATA',
	inputLabel = 'Owner Ata',
	id = 'ata',
	value: valueProp,
	onChange,
}) => {
	const [ata] = useLocalStorage('ata');

	const [value, setValue] = useState(null);
	const [shouldUseDefaultAta, setShouldUseDefaultAta] = useState(true);
	const { associatedTokenAddress: defaultAssociatedTokenAddress } = useAssociatedTokenAddress();

	const associatedTokenAddress = ata ?? defaultAssociatedTokenAddress;

	const handleCheckboxChange = useCallback(() => {
		setShouldUseDefaultAta(shouldUseDefaultAta => !shouldUseDefaultAta);
	}, []);

	useEffect(() => {
		// NOTE: By default this input will work with associatedTokenAddress but we can pass any other associated token address value from outside.
		setValue(valueProp ?? associatedTokenAddress?.toString());
	}, [valueProp, associatedTokenAddress]);

	const handleInputChange = useCallback(
		event => {
			setValue(event.target.value);
			onChange(event.target.value);
		},
		[onChange]
	);

	return (
		<>
			<div className="checkbox-wrapper">
				<input id={id} type="checkbox" onChange={handleCheckboxChange} checked={shouldUseDefaultAta} />
				<label htmlFor={id}>{checkboxLabel}</label>
			</div>
			{!shouldUseDefaultAta && (
				<div className="form-field">
					<label htmlFor={`${id}-input`}>{inputLabel}</label>
					<input type="text" name="title" id={`${id}-input`} placeholder="Enter here" required onChange={handleInputChange} value={value} />
				</div>
			)}
		</>
	);
};
