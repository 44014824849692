import { gsap } from 'gsap';
import useAsyncEffect from 'use-async-effect';
import ProgressBar from '@ramonak/react-progress-bar';
import { useNavigate, useParams } from 'react-router-dom';
import { useEventListener, useLockedBody } from 'usehooks-ts';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { adminWalletPublicAddress } from 'constants';
import { getInvestedAmount, getAllVestings } from 'api/missions';
import { MissionsContext } from 'contexts/Missions';
import toast from 'react-hot-toast';
import { useLocalStorage, useOnClickOutside } from 'usehooks-ts';

import { Image } from 'components/Image';
import { Layout } from 'components/Layout';
import { DepositUnstakeModal } from 'components/DepositUnstakeModal';
import { MissionPoolGallery } from 'components/MissionPoolGallery';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import Tippy from '@tippyjs/react';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

export const MissionPoolDetail = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { wallet, connect, connecting, connected: isConnected } = useWallet();
	const { setVisible } = useWalletModal();
	const [investedAmount, setInvestedAmount] = useState(0);
	const { missionPools, isLoading, setShouldRefresh: setShouldRefreshMissions } = useContext(MissionsContext);
	const [isDepositUnstakeModalOpen, setIsDepositUnstakeModalOpen] = useState(false);
	const [isFunding, setIsFunding] = useState(false);

	const missionPoolDetail = useMemo(() => missionPools.find(mission => mission.id === params.id) ?? {}, [missionPools, params]);
	const handleSelectWalletButtonClick = useCallback(() => setVisible(true), [setVisible]);
	const handleConnectButtonClick = useCallback(() => {
		// NOTE: Connection promise acts weird and works only after second click hens adding this hack here.
		connect().then(() => connect());
	}, [connect]);
	const [xAta] = useLocalStorage('xAta');
	const { xAssociatedTokenAddress: defaultXAssociatedTokenAddress } = useAssociatedTokenAddress();
	const xAssociatedTokenAddress = xAta ?? defaultXAssociatedTokenAddress;

	const handleDepositUnstakeButtonClick = useCallback(() => {
		setIsDepositUnstakeModalOpen(true);
		gsap.to('#deposit', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleRedeemButtonClick = useCallback(async () => {
		try {
			const reward = await getAllVestings({
				missionsWithClaims: missionPoolDetail?.bcMission,
				wallet: wallet,
				associatedTokenAddress: xAssociatedTokenAddress,
			});
			
			setShouldRefreshMissions(shouldRefreshMissions => !shouldRefreshMissions);
		} catch (error) {
			console.error(error);
			toast.error(`Error during redeeming: ${error.message}`);
		}

	});

	const handleCloseDepositUnstakeModal = useCallback(() => {
		gsap.to('.modal-success-view', { duration: 0.5, opacity: 0, display: 'none' });
		gsap.to('#deposit', { duration: 0.5, opacity: 0, display: 'none' });
		setIsDepositUnstakeModalOpen(false);
	}, []);

	useLockedBody(isDepositUnstakeModalOpen);
	useEventListener('keyup', event => {
		if (event.key === 'Escape') {
			handleCloseDepositUnstakeModal();
		}
	});

	useAsyncEffect(async () => {
		if(Math.floor(Date.now() / 1000) <= missionPoolDetail.fundingEndDate)
				setIsFunding(true);
		const blockchainMissionData = missionPoolDetail?.data;
		if (isConnected && blockchainMissionData) {
			const investedAmount = await getInvestedAmount({ blockchainMissionData: missionPoolDetail.data, wallet: wallet.adapter });
			setInvestedAmount(investedAmount);
		}
	}, [missionPoolDetail, wallet, isConnected]);

	return (
		<>
			<Layout title="Mission Pool Detail">
				<section id="mission-pool-detail">
					<div className="contentContainer">
						{isLoading ? (
							<div id="loader" />
						) : (
							<div className="row main spaced">
								<div className="col left">
									<button className="back-btn hasArrow" onClick={() => navigate(-1)}>
										<span className="icon-arrow btn-icon" />
										<span className="txt">Back</span>
									</button>
									<div className="mod-logo retina">
										<Image src={missionPoolDetail.logo} alt={missionPoolDetail.title} />
									</div>
									<h2>{missionPoolDetail.title}</h2>
									{/* featured image inline with paragraph for mobile */}
									<div className="module detail-image sized mob">
										<Image src={missionPoolDetail.backgroundImage} alt={missionPoolDetail.title} />
									</div>
									<p>{missionPoolDetail.description}</p>
									<div className="module">
										<div className="bg-glow">
											<Image src="/images/corner-gradient@2x.jpg" className="spacer" alt="" />
										</div>
										<div className="col" data-num={2}>
											<div className="row title">
												{isFunding === false && investedAmount !== 0 && missionPoolDetail.duration.includes('Ended') ? (
													<h3>Mission Ended - Redeem xArcade</h3>
												) : (
													<h3>Funded</h3>
												)}
											</div>
											<div className="row amount">
												<p className="lrg">{investedAmount}</p>
												<p className="sm" style={{ color: '#fece02' }}>
													xARCADE
												</p>
												<div className="pool-size-progress">
													<p>Pool Size</p>
													<div className="progress-wrapper">
														<ProgressBar
															completed={missionPoolDetail.currentVesting}
															customLabel={' '}
															maxCompleted={missionPoolDetail.target}
														/>
														<strong>
															{missionPoolDetail.currentVesting} / {missionPoolDetail.target}
														</strong>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="data-cols">
										<div className="col cta" style={{ width: '100%' }}>
											{!wallet ? ( // No wallet, just show a connect button
												<button
													style={{ width: '100%' }}
													className="deposit-btn cta-btn large overlay-btn"
													onClick={handleSelectWalletButtonClick}
												>
													<Image src="/images/icons/wallet-full.svg" className="cta-icon" alt="Wallet" />
													<span>Connect</span>
												</button>
												) : (wallet && !isConnected) ? (
														<button
															style={{ width: '100%' }}
															className="deposit-btn cta-btn large overlay-btn"
															onClick={handleConnectButtonClick}
														>
															<span>{connecting ? 'Connecting…' : 'Connect'}</span>
														</button>
											 ) : isFunding === false && investedAmount !== 0 && missionPoolDetail.duration.includes('Ended') ? ( // We've ended AND we have invested some
												<button
													style={{ width: '100%' }}
													className="deposit-btn cta-btn large overlay-btn"
													onClick={handleRedeemButtonClick}
													disabled={!wallet}
													visible={investedAmount}
												>
													<span>{investedAmount > 0 ? 'Redeem Rewards' : 'No'}</span>
												</button>
											) : isFunding === false && investedAmount === 0 && !adminWalletPublicAddress.includes(wallet.adapter.publicKey.toString()) ? (
												<Tippy content="Mission is not in its funding period">
													<div>
														<button style={{ width: '100%' }} className="deposit-btn cta-btn large overlay-btn" disabled>
															<span>{investedAmount > 0 ? 'Withdraw ' : 'Deposit'}</span>
														</button>
													</div>
												</Tippy>
											) : missionPoolDetail.duration.includes('Ended') ? (
												<></>
											) : (
												<button
													style={{ width: '100%' }}
													className="deposit-btn cta-btn large overlay-btn"
													onClick={handleDepositUnstakeButtonClick}
													disabled={!wallet}
												>
													<span>{investedAmount > 0 ? 'Withdraw' : 'Deposit'}</span>
												</button>
											)}
										</div>
									</div>
									<div className="data-cols">
										<div className="col">
											<p className="title">Pool Size</p>
											<p>{missionPoolDetail.poolSize}</p>
										</div>
										<div className="col">
											<p className="title">Duration</p>
											<p dangerouslySetInnerHTML={{ __html: missionPoolDetail.duration }}></p>
										</div>
										<div className="col">
											<p className="title">Historical Rewards</p>
											<p>{missionPoolDetail.expectedRewards}</p>
										</div>
										<div className="col">
											<p className="title">MPO</p>
											<p>Arcade</p>
										</div>
									</div>
								</div>
								<div className="col right">
									<div className="module detail-image sized">
										<Image src={missionPoolDetail.backgroundImage} alt={missionPoolDetail.title} />
									</div>
								</div>
							</div>
						)}
					</div>
				</section>
			</Layout>
			<MissionPoolGallery images={missionPoolDetail.images} />
			<DepositUnstakeModal
				hasInvested={investedAmount > 0}
				investedAmount={investedAmount}
				setInvestedAmount={setInvestedAmount}
				isOpen={isDepositUnstakeModalOpen}
				onClose={handleCloseDepositUnstakeModal}
				missionCardProps={missionPoolDetail}
				isFunding={isFunding}
			/>
		</>
	);
};
