import { Keyboard } from 'swiper';
import { memo, useCallback, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useAsyncEffect from 'use-async-effect';

import { Image } from 'components/Image';

const mockGalleryImages = [
	'/images/pool-gallery1@2x.webp',
	'/images/pool-gallery2@2x.webp',
	'/images/pool-gallery3@2x.webp',
	'/images/pool-gallery4@2x.webp',
];

const PureMissionPoolGallery = ({ images }) => {
	const swiperRef = useRef(null);
	const [missionImages, setMissionImages] = useState(mockGalleryImages);
	const handlePrevButtonClick = useCallback(() => swiperRef.current.swiper.slidePrev(), []);
	const handleNextButtonClick = useCallback(() => swiperRef.current.swiper.slideNext(), []);

	useAsyncEffect(async () => {
		if (images !== missionImages && images !== undefined) {
			setMissionImages(images);
		}
	}, [images]);

	return (
		<section id="mission-pool-gallery">
			{/* bottom gradient for mobile */}
			<div className="bg-gradient mob">
				<div className="bg-glow">
					<Image src="/images/bg-gradient@2x.jpg" className="spacer" alt="" />
				</div>
			</div>
			<div className="contentContainer">
				{/* Gallery Slider */}
				<div className="section hasModSlider">
					<header>
						<h2>Gallery</h2>
						<div className="slide-controls">
							<button className="arrow-btn left" onClick={handlePrevButtonClick}>
								<div className="icon-arrow" />
							</button>
							<button className="arrow-btn right" onClick={handleNextButtonClick}>
								<div className="icon-arrow" />
							</button>
						</div>
					</header>
					<div className="mod-slider">
						<div className="mod-slides" id="mod-swiper">
							<Swiper
								loop
								draggable
								width={1530}
								ref={swiperRef}
								freeMode={true}
								spaceBetween={50}
								slidesPerView={3}
								modules={[Keyboard]}
								keyboard={{ enabled: true }}
							>
								{missionImages.map(image => (
									<SwiperSlide key={image}>
										<div className="module mod-slide">
											<Image src={image} className="spacer" alt="" />
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const MissionPoolGallery = memo(PureMissionPoolGallery);
