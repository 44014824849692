import { Keyboard } from 'swiper';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';

import { macroToMicro } from 'utils';
import { getInvestedAmount, getCompletedMissions } from 'api/missions';

import { MissionsContext } from 'contexts/Missions';
import { BalancesContext } from 'contexts/Balances';

import { Image } from 'components/Image';
import { Layout } from 'components/Layout';
import { MissionCard } from 'components/MissionCard';

import 'swiper/css';

export const Dashboard = () => {
	const navigate = useNavigate();
	const anchorWallet = useAnchorWallet();
	const { setVisible } = useWalletModal();

	const swiperRef = useRef(null);
	const [activeMissions, setActiveMissions] = useState([]);
	const [pendingMissions, setPendingMissions] = useState([]);
	const [completedMissions, setCompletedMissions] = useState([]);
	const [isLoadingMyMissions, setIsLoadingMyMissions] = useState(anchorWallet);

	const { reward, isLoadingRewardBalance } = useContext(BalancesContext);
	const { missionPools, isLoading: isLoadingActiveMissionPools } = useContext(MissionsContext);

	const [activeMissionPools, setMyMissions] = useState([]);

	useEffect(() => {
		let active = true;
		filterMissions()
		return ()=> { active = false;}
		
		async function filterMissions() {
			console.log('filter missions');
			const filteredMissions = [];
			for(var i = 0; i < missionPools.length; i++){
				if(missionPools[i].bcGamePhase !== 3){
					if(missionPools[i].gamePhase !== 2 || missionPools[i].gamePhase == 1) 
					{
						if( missionPools[i].duration >= Math.floor((new Date).getTime()/1000)){
							filteredMissions.push(missionPools[i]);
						}else if(missionPools[i]?.data !== undefined){
								const res = await getInvestedAmount({ blockchainMissionData: missionPools[i]?.data, wallet: anchorWallet });
								if (res > 0) {
									filteredMissions.push(missionPools[i]);
								}
							}
						
					}else{
						//console.log('throw away: ', missionPools[i].title)
					}
				}
			}
			setMyMissions(filteredMissions);
		}
	}, [missionPools, anchorWallet])

	const handlePrevButtonClick = useCallback(() => swiperRef.current.swiper.slidePrev(), []);
	const handleNextButtonClick = useCallback(() => swiperRef.current.swiper.slideNext(), []);

	const handleSelectWalletButtonClick = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	useEffect(() => {
		if (!anchorWallet) {
			navigate('/');
		}
	});

	useEffect(() => {
		if (anchorWallet) {
			setIsLoadingMyMissions(true);
		}
	}, [anchorWallet]);

	useAsyncEffect(async() => {
		try {
			if (anchorWallet && !isEmpty(missionPools)) {
				var comp = await getCompletedMissions({ wallet: anchorWallet });
				setCompletedMissions([]);
				setActiveMissions([])
				setPendingMissions([]);
				setCompletedMissions(comp.data);
				missionPools.forEach(async (mission, index) => {
					if(mission?.base !== undefined){
						const investedAmount = await getInvestedAmount({ blockchainMissionData: mission?.data, wallet: anchorWallet });

						// NOTE: Why not use value of "mission.bcMission.currentVesting" here?
						// Or 'getInvestedAmount' method returns more up to date result.
						if (investedAmount > 0) {
							/*console.log({
								//
								index,
								id: mission.id,
								duration: mission.duration,
								gamePhase: mission.gamePhase,
								investedAmount,
								currentVesting: macroToMicro(mission.bcMission.currentVesting),
							});*/

							// TODO: gamePhase field doesn't work properly.
							// TODO: If the duration is ended shouldn't the back-end update the phase of the mission?
							if (mission.gamePhase === 1 && mission.duration !== 'Ended') {
								setActiveMissions(missions => uniqBy([...missions, mission], 'id'));
							}

							if (mission.gamePhase === 2) {
								setPendingMissions(missions => uniqBy([...missions, mission], 'id'));
							}

							if (mission.gamePhase === 3) {
								setCompletedMissions(missions => uniqBy([...missions, mission], 'id'));
							}
						}
					}
				});
			}
		} catch (error) {
			console.error('🚀 ~ file: Dashboard.js ~ line 61 ~ error', error);
		} finally {
			setIsLoadingMyMissions(false);
		}
	}, [anchorWallet, missionPools]);

	const myMissions = useMemo(
		() => [
			{
				title: 'Active',
				icon: `/images/icons/wave${isLoadingMyMissions || isLoadingActiveMissionPools ? '-loader' : ''}.svg`,
				value: activeMissions.length,
			},
			{
				title: 'Completed',
				icon: `/images/icons/check${isLoadingMyMissions || isLoadingActiveMissionPools ? '-loader' : ''}.svg`,
				value: completedMissions.length,
			},
			{
				title: 'Total',
				icon: `/images/icons/diamond${isLoadingMyMissions || isLoadingActiveMissionPools ? '-loader' : ''}.svg`,
				value: activeMissions.length + completedMissions.length + pendingMissions.length,
			},
		],
		[activeMissions, completedMissions, pendingMissions, isLoadingMyMissions, isLoadingActiveMissionPools]
	);

	return (
		<Layout title="Dashboard">
			<section id="dashboard">
				<div className="contentContainer">
					{/* Missions Stats */}
					<div className="section top">
						<div className="col left missions">
							<h2>My Missions</h2>
							<div className="module">
								<div className="bg-glow">
									<Image src="/images/corner-gradient@2x.jpg" className="spacer" alt="" />
								</div>
								{myMissions.map(({ title, icon, value }, index) => (
									<div className="col" data-num={index + 1} key={title}>
										<div className="row title">
											<h3>{title}</h3>
											<Image src={icon} className="icon" alt={title} />
										</div>
										<div className="row amount">
											<p className="lrg">{value}</p>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="col right locked">
							<h2>Total xArcade Locked</h2>
							<div className="module">
								<div className="bg-glow">
									<Image src="/images/corner-gradient@2x.jpg" className="spacer" alt="" />
								</div>
								<div className="row title">
									<h3>xARCADE</h3>
								</div>
								<div className="row amount spaced">
									{anchorWallet ? (
										<p className="lrg">{isLoadingRewardBalance ? '...' : reward.total}</p>
									) : (
										<button onClick={handleSelectWalletButtonClick} className="connect-wallet-p">
											Connect Wallet
										</button>
									)}
									<div className="coin">
										<Image src="/images/coin-sprite.png" className="sprite" alt="Coin Sprite" />
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Active Missions Slider */}
					<div className="section bot hasModSlider">
						<header style={{ display: 'flex' }}>
							<h2 className="empty-mission-pools">
								Active Mission Pools
								{!isLoadingActiveMissionPools && isEmpty(activeMissionPools) && (
									<>
										{' '}
										are empty{' '}
										<svg width="32" height="31" viewBox="0 0 32 31" fill="#fece02">
											<path d="M15.637 17.996 4.69 12.188l-3.078 3.718c3.883 2.07 7.766 4.145 11.653 6.219Zm.847-11.82L5.5 11.68l10.707 5.68 10.73-5.692-6.152-3.25ZM4.137 11.562.102 7.704a.418.418 0 0 1 .039-.586.371.371 0 0 1 .078-.058L12.489.05a.408.408 0 0 1 .546.16l3.445 4.793L18.777.66a.41.41 0 0 1 .555-.176l12.445 6.649c.04.02.07.043.102.074a.417.417 0 0 1-.004.586l-3.688 3.66 3.57 4.313a.422.422 0 0 1-.05.586.642.642 0 0 1-.086.054l-3.203 1.711v6.418c0 .172-.105.32-.258.383l-11.59 5.941a.415.415 0 0 1-.36.133.416.416 0 0 1-.312-.226L4.285 24.902a.42.42 0 0 1-.226-.37v-6.384L.793 16.406a.415.415 0 0 1-.133-.64Zm11.707-6.019L12.527.969 1.06 7.55l3.75 3.523Zm1.277.004 3.844 2.031 6.531 3.399 3.402-3.375-11.59-6.192Zm10.606 6.64-10.946 5.81 2.371 4.124 11.653-6.219Zm0 0" />
										</svg>
									</>
								)}
							</h2>
							{!isLoadingActiveMissionPools && !isEmpty(activeMissionPools) && (
								<div className="slide-controls">
									<button className="arrow-btn left" onClick={handlePrevButtonClick}>
										<div className="icon-arrow" />
									</button>
									<button className="arrow-btn right" onClick={handleNextButtonClick}>
										<div className="icon-arrow" />
									</button>
								</div>
							)}
						</header>
						<div className="mod-slider">
							{isLoadingActiveMissionPools ? (
								<div id="loader" />
							) : (
								<Swiper
									draggable
									preventClicks
									preventClicksPropagation
									slideToClickedSlide={false}
									width={1530}
									ref={swiperRef}
									freeMode={true}
									spaceBetween={0}
									slidesPerView={3}
									modules={[Keyboard]}
									keyboard={{ enabled: true }}
								>
									{activeMissionPools.map(missionPoolProps => (
										<SwiperSlide key={missionPoolProps.id}>
											<MissionCard {...missionPoolProps} />
										</SwiperSlide>
									))}
								</Swiper>
							)}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};
