import useAsyncEffect from 'use-async-effect';
import { createContext, useState } from 'react';

import { fetchMissionPools } from 'api/missions';
import { getDaysAndHoursFromEndDateDiff, macroToMicro } from 'utils';

function getGameLogo(game) {
	switch (game) {
		case 'Star Atlas':
			return '/images/logo-staratlas@2x.png';
		case 'Illuvium':
			return '/images/logo-illuvium@2x.png';
		case 'Mavia':
			return '/images/heroesofmavia-2x.png';
		case 'ASM':
			return '/images/logo-asm@2x.png';
		default:
			return '/images/logo-staratlas@2x.png';
	}
}

export const prepareMission = mission => ({
	id: mission._id,
	ata: mission?.bcMission?.ata,
	bcMission: mission?.bcMission,
	data: mission?.bcMission?.data,
	base: mission?.bcMission?.base,
	gamePhase: mission.gamePhase || 0,
	bcGamePhase: mission?.bcGamePhase || 0,
	gameType: mission.game,
	title: mission.title,
	description: mission.description,
	currentVesting: macroToMicro(mission?.bcMission?.currentVesting),
	target: macroToMicro(mission?.bcMission?.target),
	// NOTE: Hardcoded logo & background image.
	sponsors: mission.sponsors,
	logo: getGameLogo(mission.game),
	images: mission.images,
	backgroundImage: mission.default_image,
	poolSize: macroToMicro(mission.max_pool_size),
	duration: getDaysAndHoursFromEndDateDiff(mission.duration),
	endDate: mission.duration,
	expectedRewards: macroToMicro(mission.expected_rewards),
	expectedRewardsCoin: mission.expected_rewards_coin,
	max_pool_size: macroToMicro(mission.max_pool_size),
	feePercent: mission.feePercent,
	maxVestingPercent: mission.maxVestingPercent,
	poolID: mission.poolID,
	fundingEndDate: mission.fundingEndDate,
	trustedOwnerPub: mission.trustedOwnerPub
});

export const MissionsContext = createContext(null);

export const MissionsContextProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [missionPools, setMissionPools] = useState([]);
	const [shouldRefresh, setShouldRefresh] = useState(false);

	useAsyncEffect(async () => {
		const rawMissionPools = await fetchMissionPools();
		const mappedMissionsPools = rawMissionPools.map(prepareMission);
		
		setMissionPools(mappedMissionsPools);
		setIsLoading(false);
	}, [shouldRefresh]);

	return (
		<MissionsContext.Provider value={{missionPools, setMissionPools, isLoading, setShouldRefresh }}>{children}</MissionsContext.Provider>
	);
};
