import { useMemo } from 'react';
import { PublicKey } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { BrowserRouter, Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';

import { Admin } from 'pages/Admin';
import { WarChest } from 'pages/WarChest';
// import { Overview } from 'pages/Overview';
import { Dashboard } from 'pages/Dashboard';
import { MissionPools } from 'pages/MissionPools';
import { WalletCenter } from 'pages/WalletCenter';
import { MissionPoolDetail } from 'pages/MissionPoolDetail';

import { adminWalletPublicAddress } from 'constants';

export const Routes = () => {
	const { publicKey } = useWallet();

	const connectedWalletPublicAddress = useMemo(() => publicKey && new PublicKey(publicKey).toString(), [publicKey]);

	return (
		<BrowserRouter>
			<RouterRoutes>
				<Route index element={publicKey ? <Dashboard /> : <MissionPools />} />
				{/* <Route index element={<Overview />} /> */}
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/mission-pools" element={<MissionPools />} />
				<Route path="/war-chest" element={<WarChest />} />
				<Route path="/wallet-center" element={<WalletCenter />} />
				<Route path="/mission-pools/:id" element={<MissionPoolDetail />} />
				{adminWalletPublicAddress.includes(connectedWalletPublicAddress) && <Route path="/admin" element={<Admin />} />}
				<Route path="*" element={<Navigate to="/" replace />} />
			</RouterRoutes>
		</BrowserRouter>
	);
};
