import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { useAsyncEffect } from 'use-async-effect';
import { memo, useState, useContext, useMemo, useEffect } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { Image } from 'components/Image';
import { solanaEnvSig } from 'api/solana';
import { getInvestedAmount } from 'api/missions';
import { MissionsContext } from 'contexts/Missions';
import { numberWithCommas } from 'utils';

import { getGamePhaseTextFromBC } from 'utils';

export const PureMissionCard = ({
	id,
	title,
	logo,
	backgroundImage,
	poolSize,
	duration,
	endDate,
	gamePhase,
	bcGamePhase,
	mission_mint,
	currentVesting,
	expectedRewards,
	onDeleteButtonClick,
	onRewardButtonClick,
	onApproveButtonClick,
	feePercent,
	maxVestingPercent,
	images,
	hasEnterButton = true,
	hasAdminButtons = false,
	fundingEndDate,
	...otherProps
}) => {
	const wallet = useAnchorWallet();
	const [investedAmount, setInvestedAmount] = useState(0);
	const { missionPools } = useContext(MissionsContext);
	const [AdminButtons, setHasAdminButtons] = useState(false);
	const [MPOButtons, setHasMPOButtons] = useState(false);
	const [isFunding, setIsFunding] = useState(false);
	useAsyncEffect(async () => {
		const { trustedOwnerPub } = solanaEnvSig(wallet);
		let trusted = trustedOwnerPub.filter(pubkey => pubkey === wallet?.publicKey);
		if (trusted) setHasAdminButtons(true);
		else setHasMPOButtons(true);
		
	}, [wallet]);
	const [gameStatusclass, setGameStatusClass] = useState('');
	const [gameStatusText, setGameSatusText] = useState('');
	const missionPoolDetail = useMemo(() => missionPools.find(mission => mission.id === id) ?? {}, [missionPools, id]);

	const getStatusFromGamePhase = (phase) => {
		switch (phase) {
			case 0:
				setGameStatusClass('c-pill c-pill--coming');
				setGameSatusText('Preparing');
				break;
			case 1:
				setGameStatusClass('c-pill c-pill--success');
				setGameSatusText('Funding');
				break;
			case 2:
				setGameStatusClass('c-pill c-pill--warning');
				setGameSatusText('Pending');
				break;
			case 3:
				setGameStatusClass('c-pill c-pill--warning');
				setGameSatusText('Ended');
				break;
			case 4:
				setGameStatusClass('c-pill c-pill--coming');
				setGameSatusText('Transitionary');
				break;
			case 5:
				setGameStatusClass('c-pill c-pill--danger');
				setGameSatusText('Archived');
				break;
			default:
				setGameStatusClass('c-pill c-pill--coming');
				setGameSatusText('Preparing');
				break;
		}
		return;
	}

	useAsyncEffect(async () => {
		const blockchainMissionData = missionPoolDetail?.data;
		if (blockchainMissionData) {
			let phase = missionPoolDetail?.bcGamePhase;
			if (wallet) {
				const investedAmount = await getInvestedAmount({ missionPoolDetail, blockchainMissionData: missionPoolDetail.data, wallet });
				if (Math.floor(Date.now() / 1000) <= fundingEndDate)
					setIsFunding(true);
				setInvestedAmount(investedAmount);
				if (investedAmount === 0 && missionPoolDetail.bcGamePhase === 3)
					phase = 4;
			}
			//getStatusFromGamePhase(5);
			getStatusFromGamePhase(missionPoolDetail?.bcGamePhase);
			if (missionPoolDetail?.bcGamePhase === 1 && missionPoolDetail.fundingEndDate < Date.now()) {
				setGameSatusText('Pending')
			}
		}
	}, [missionPoolDetail, wallet]);
	return (
		<div
			className="module module-thumb"
			style={{ backgroundImage: `url(${backgroundImage})`, filter: gamePhase === 0 ? 'grayscale(1)' : '' }}
		>
			<Link className="" to={gamePhase === 0 ? '#' : `/mission-pools/${id}`}>
			<div className="mod-logo retina">
				<Image src={logo} alt={title} />
			</div>
			<div className="mod-details">
				<div className="row data">
						{//<Link className="mission-title" to={gamePhase === 0 ? '#' : `/mission-pools/${id}`}>
						}
						<h3>{gamePhase === 0 ? 'Coming Soon!' : title}</h3>
					{//</Link> 
						}
					<div className="data-cols">
						<table className="table">
							<tbody>
								<tr>
									<td>
										<p className="title">Max Pool Size</p>
									</td>
									<td>{gamePhase === 0 ? '...' : numberWithCommas(poolSize)}</td>
								</tr>
								<tr>
									<td>
										<p className="title">End Date</p>
									</td>
									<td>
										<p dangerouslySetInnerHTML={{ __html: gamePhase === 0 ? '...' : duration }}></p>
									</td>
								</tr>
								<tr>
									<td>
										<Tippy content="Historical Rewards are based on the average rewards generated by similar types of missions for the selected game">
											<p className="title"> Historical Rewards </p>
										</Tippy>
									</td>
									<td>{gamePhase === 0 ? '...' : numberWithCommas(expectedRewards) + " xArcade"}</td>
								</tr>
								<tr>
									<td>
										<p className="title">Mission Phase</p>
									</td>
									<td>
										<span className={gameStatusclass}>{gameStatusText}</span>
									</td>
								</tr>
								<tr>
									<td>
										<p className="title">My Contributed Amount</p>
									</td>
									<td>{gamePhase === 0 ? '...' : investedAmount ? numberWithCommas(investedAmount) + " xArcade" : "Haven't contributed yet"}</td>
								</tr>
							</tbody>
						</table>

						{/* {hasEnterButton && (
							<div className="col cta">
								<Link className="cta-btn" to={`/mission-pools/${id}`} >
									<span>Enter</span>
								</Link>
							</div>
						)} */}
					</div>
						{//<Link to={`/mission-pools/${id}`} className="mod-btn-mobile">
						}	<div className="icon-arrow mod-btn-mobile" style={{ color: '#fece02'}} />
						{//</Link>
						}
				</div>
				</div>
				</Link>
			<div className="mod-actions">
				{hasAdminButtons ? (
					<div className="row logos spaced">
						<div className="col">
							{hasAdminButtons ? (
								/*currentVesting > 0 ? (
								<Tippy content="The mission can't be deleted because there are pending vestings">
									<div>
										<button className="cta-btn" disabled>
											<span>Delete</span>
										</button>
									</div>
								</Tippy>
							) : (*/
								<button
									className="cta-btn danger"
									onClick={() =>
										onDeleteButtonClick({
											id,
											title,
											logo,
											backgroundImage,
											poolSize,
											duration,
											expectedRewards,
											gamePhase,
											endDate,
											currentVesting,
											...otherProps,
										})
									}
								>
									<span>Delete</span>
								</button>
							) : (
								//)
								<div className="logo retina">
									<Image src="/images/logo-cpu@2x.png" alt="" style={{ maxWidth: '93px' }} />
								</div>
							)}
						</div>
						<div className="col">
							{
								hasAdminButtons && AdminButtons ? (
									gamePhase === 0 ? (
										<button
											className="cta-btn"
											onClick={() =>
												onApproveButtonClick({
													id,
													title,
													logo,
													backgroundImage,
													poolSize,
													duration,
													expectedRewards,
													feePercent,
													gamePhase,
													endDate,
													maxVestingPercent,
													...otherProps,
												})
											}
										>
											<span>Approve</span>
										</button>
									) : bcGamePhase !== 2 ? (
										<Tippy content="The mission must be in pending game-phase in order to reward.">
											<div>
												<button className="cta-btn" disabled>
													<span>Reward</span>
												</button>
											</div>
										</Tippy>
									) : (
										<button
											className="cta-btn"
											onClick={() =>
												onRewardButtonClick({
													id,
													title,
													logo,
													backgroundImage,
													poolSize,
													endDate,
													duration,
													expectedRewards,
													gamePhase,
													...otherProps,
												})
											}
										>
											<span>Reward</span>
										</button>
									)
								) : (
									<> </>
								) /*: (
							<div className="logo retina">
								<Image src="/images/logo-bga@2x.png" alt="" style={{ maxWidth: '123px' }} />
							</div>
						)*/
							}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export const MissionCard = memo(PureMissionCard);
