import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';
import { useCallback, useContext, useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { microToMacro } from 'utils';
import { endTask, returnVestings } from 'api/missions';
import { /*solanaEnvSAS,solanaEnvNoSig,*/ solanaEnvSig } from 'api/solana';
import { MissionsContext } from 'contexts/Missions';
import { BalancesContext } from 'contexts/Balances';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

import { Loader } from 'components/Loader';
import { MissionCard } from 'components/MissionCard';
import { DefaultAtaInput } from 'components/DefaultAtaInput';

export const RewardMissionModal = ({ onClose, missionCardProps }) => {
	const wallet = useAnchorWallet();
	const [xAta] = useLocalStorage('xAta');
	const [shouldShowAtaOptions] = useLocalStorage('shouldShowAtaOptions');

	const [ownerAta, setOwnerAta] = useState(null);
	const [rewardAmount, setRewardAmount] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { account } = useContext(BalancesContext);
	const [isMissionFailed, setIsMissionFailed] = useState(false);
	const { xAssociatedTokenAddress: defaultXAssociatedTokenAddress } = useAssociatedTokenAddress();
	const { setShouldRefresh: setShouldRefreshMission, setMissionPools } = useContext(MissionsContext);

	const xAssociatedTokenAddress = xAta ?? defaultXAssociatedTokenAddress;

	const handleRewardAmountInputChange = useCallback(event => {
		// eslint-disable-next-line no-useless-escape
		let value = event.target.value.replace(/[^0-9\.]/g, '');

		if (value.split('.').length > 2) {
			value = value.replace(/\.+$/, '');
		}

		setRewardAmount(value);
	}, []);

	const handleDefaultAtaInputChange = useCallback(value => {
		setOwnerAta(value);
	}, []);

	const handleMissionFailedCheckboxChange = useCallback(() => {
		setIsMissionFailed(isMissionFailed => !isMissionFailed);
	}, []);

	const handleSubmitButtonClick = useCallback(async () => {
		setIsLoading(true);
		if (rewardAmount <= 0 && !isMissionFailed) {
			toast.error('The reward amount must be greater than zero!');
			setIsLoading(false);

			return;
		}

		try {
			if (wallet) {
				if(!isMissionFailed){
					await endTask({
						isSuccess: rewardAmount !== 0,
						amount: isMissionFailed ? 0 : microToMacro(rewardAmount),
						blockchainMission: missionCardProps?.bcMission,
						ownerAta: ownerAta ?? xAssociatedTokenAddress,
						wallet,
					});
				}else{
					const { program } = solanaEnvSig();
					await returnVestings(
						missionCardProps,
						wallet,
						program
					);
				}
			}

			setMissionPools(missionPools =>
				missionPools.map(mission => {
					if (mission.id === missionCardProps.id) {
						return { ...mission, gamePhase: 3 };
					}

					return mission;
				})
			);

			setShouldRefreshMission(shouldRefresh => !shouldRefresh);
			toast.success(`You have successfully reward ${rewardAmount} xArcade! The mission now has a closed game-phase!`);
		} catch (error) {
			toast.error(`Error during rewarding: ${error.message}`);
			console.error(error);
		} finally {
			setIsLoading(false);
			onClose();
		}
	}, [
		rewardAmount,
		wallet,
		setMissionPools,
		setShouldRefreshMission,
		isMissionFailed,
		missionCardProps,
		ownerAta,
		xAssociatedTokenAddress,
		onClose,
	]);

	return (
		<div id="reward-mission" className="overlay deposit global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose} disabled={isLoading}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={onClose}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					{/* FORM */}
					<div id="deposit-form" className="row main spaced">
						<div className="col left">
							<div className="modal-title">
								<div className="row title">
									<h3>Reward</h3>
									<p>
										<span className="mobile-break">Balance:</span> {account.xArcade}
										<span className="coin-icon x-arcade" title="xArcade" />
									</p>
								</div>
								<div className="checkbox-wrapper">
									<input id="mission-failed" type="checkbox" onChange={handleMissionFailedCheckboxChange} checked={isMissionFailed} />
									<label htmlFor="mission-failed">Mission Failed</label>
								</div>
								{!isMissionFailed && (
									<div className="row field">
										<div className="col left">
											<label htmlFor="deposit-amount">Amount to reward</label>
											<input
												id="deposit-amount"
												type="text"
												name="amount"
												placeholder="0"
												value={rewardAmount}
												onChange={handleRewardAmountInputChange}
											/>
										</div>
										<div className="col right">
											<span className="coin-icon x-arcade" title="xArcade" />
										</div>
									</div>
								)}
								{shouldShowAtaOptions && <DefaultAtaInput id="reward-mission-ata" onChange={handleDefaultAtaInputChange} />}
								<div className="row cta">
									<button className="cta-btn large submit" onClick={handleSubmitButtonClick} disabled={isLoading}>
										{isLoading && <Loader />}
										<span>{isLoading ? 'Please wait...' : 'Reward'}</span>
									</button>
								</div>
							</div>
						</div>
						<div className="col right">
							<MissionCard {...missionCardProps} hasEnterButton={false} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
