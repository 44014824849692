import Joyride from 'react-joyride';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { useLocalStorage } from 'usehooks-ts';
import Select, { components } from 'react-select';
import { useCallback, useContext, useMemo, useState } from 'react';

import { getInvestedAmount } from 'api/missions';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { Layout } from 'components/Layout';

import { MissionsContext } from 'contexts/Missions';
import { MissionCard } from 'components/MissionCard';

import { getGamePhaseTextFromBC } from 'utils';
const joyrideStepsStyleOptions = {
	options: {
		arrowColor: '#fff',
		backgroundColor: '#fff',
		primaryColor: '#fece02',
		textColor: '#000',
	},
};

const joyrideSteps = [
	{
		target: '.main',
		title: 'Navigate the Arcade Platform',
		content: 'Stay up to date on current Missions available via our "Mission Pool"!',
	},
	{
		target: '.module.module-thumb',
		title: 'Checkout this mission!',
		content: 'Each mission relates back to a game. Click on the mission to get involved!',
	},
	{
		target: '.react-select',
		title: 'Filter by game!',
		content: 'Do you have a favorite game you want to participate in? Filter the mission pools for the game of your choice!',
	},
	{
		target: '.db-balance > .connect-button',
		title: 'Get started here!',
		content: 'Now that you know how to navigate, get started now by connecting your wallet!',
	},
];

export const MissionPools = () => {
	const [joyrideStatus, setJoyride] = useLocalStorage('joyride', 'started');

	const { missionPools, isLoading } = useContext(MissionsContext);
	const [selectedGameType, setSelectedGameType] = useState({ value: 'all', label: 'All' });
	const [selectedStatus, setSelectedStatus] = useState({value: 'all', label: 'All'});
	const wallet = useAnchorWallet();
	const gameTypeOptions = useMemo(
		() =>
			uniqBy(
				missionPools.map(({ gameType }) => ({ value: gameType, label: gameType })),
				'value'
			),
		[missionPools]
	);

	const gameStatusOptions = useMemo(
		() =>
			uniqBy(
				missionPools.map(({ bcGamePhase, endDate, gamePhase }) => ({ value: getGamePhaseTextFromBC(bcGamePhase, endDate), label: getGamePhaseTextFromBC(bcGamePhase, endDate) })),
				'value'
			),
		[missionPools, selectedGameType]
	);

	const handleJoyrideCallback = useCallback(
		data => {
			if (data.status === 'ready') {
				setJoyride('ready');
			}
		},
		[setJoyride]
	);

	const handleGameChange = useCallback(nextSelectedGameType => {
		setSelectedGameType(nextSelectedGameType);
	}, []);

	const handleStatusChange = useCallback(nextSelectedGameType => {
		setSelectedStatus(nextSelectedGameType);
	}, []);

	const customSort = (a, b) => {
		if (a.gamePhase === 0)
			return 1;
		if (a.gamePhase === 1) {
			if (a.gamePhase === b.gamePhase) {
				if (a.duration > b.duration)
					return -1;
				return 1;
			} 
			return 1;
		}
		if (a.gamePhase === b.gamePhase) {
			if (a.duration > b.duration)
				return -1;
			return 1;
		} 
	}

	const missionCards = useMemo(
		() =>
			selectedGameType.value === 'all' && selectedStatus.value === 'all'
				? missionPools.filter(
					async ({ data, gamePhase }) =>
						(gamePhase > 0 && wallet ? await getInvestedAmount({ blockchainMissionData: data, wallet }):'')
				).sort((a,b)=>customSort(a,b)) : selectedGameType.value === 'all' 
				? missionPools.filter(
					({ bcGamePhase, endDate }) => 
						getGamePhaseTextFromBC(bcGamePhase, endDate) === selectedStatus.value
					).sort((a, b) => customSort(a,b)) : selectedStatus.value === 'all'
				? missionPools.filter(
					({ gameType }) => 
						gameType === selectedGameType.value
						).sort((a, b) => customSort(a, b)) : missionPools.filter(
					({ gameType, bcGamePhase, endDate }) =>
						gameType === selectedGameType.value && getGamePhaseTextFromBC(bcGamePhase, endDate) === selectedStatus.value
						).sort((a, b) => customSort(a, b)),
		[missionPools, selectedGameType.value, selectedStatus.value]
	);

	return (
		<Layout title="Mission Pools">
			<section id="mission-pools">
				<div className="contentContainer">
					<h2 className="headline-mobile mob">Active Mission Pools
								{!missionCards && isEmpty(missionCards) && (
									<>
										{' '}
										are empty{' '}
										<svg width="32" height="31" viewBox="0 0 32 31" fill="#fece02">
											<path d="M15.637 17.996 4.69 12.188l-3.078 3.718c3.883 2.07 7.766 4.145 11.653 6.219Zm.847-11.82L5.5 11.68l10.707 5.68 10.73-5.692-6.152-3.25ZM4.137 11.562.102 7.704a.418.418 0 0 1 .039-.586.371.371 0 0 1 .078-.058L12.489.05a.408.408 0 0 1 .546.16l3.445 4.793L18.777.66a.41.41 0 0 1 .555-.176l12.445 6.649c.04.02.07.043.102.074a.417.417 0 0 1-.004.586l-3.688 3.66 3.57 4.313a.422.422 0 0 1-.05.586.642.642 0 0 1-.086.054l-3.203 1.711v6.418c0 .172-.105.32-.258.383l-11.59 5.941a.415.415 0 0 1-.36.133.416.416 0 0 1-.312-.226L4.285 24.902a.42.42 0 0 1-.226-.37v-6.384L.793 16.406a.415.415 0 0 1-.133-.64Zm11.707-6.019L12.527.969 1.06 7.55l3.75 3.523Zm1.277.004 3.844 2.031 6.531 3.399 3.402-3.375-11.59-6.192Zm10.606 6.64-10.946 5.81 2.371 4.124 11.653-6.219Zm0 0" />
										</svg>
									</>
								)}
							</h2>
					{!isLoading && (
						<div className="filter-wrapper">
							<Select
								isSearchable={false}
								className="react-select"
								value={selectedGameType}
								onChange={handleGameChange}
								classNamePrefix="game-select"
								options={[{ value: 'all', label: 'All' }, ...gameTypeOptions]}
								components={{
									IndicatorSeparator: () => null,
									DropdownIndicator: props => (
										<components.DropdownIndicator {...props}>
											<div className="icon-arrow" style={{ color: props.selectProps.menuIsOpen ? '#fece02' : '#e019f9' }} />
										</components.DropdownIndicator>
									),
								}}
							/>
							<Select
								isSearchable={false}
								className="react-select"
								value={selectedStatus}
								onChange={handleStatusChange}
								classNamePrefix="react-select"
								options={[{ value: 'all', label: 'All' }, ...gameStatusOptions]}
								components={{
									IndicatorSeparator: () => null,
									DropdownIndicator: props => (
										<components.DropdownIndicator {...props}>
											<div className="icon-arrow" style={{ color: props.selectProps.menuIsOpen ? '#fece02' : '#e019f9' }} />
										</components.DropdownIndicator>
									),
								}}
							/>
						</div>
					)}

					<div className="modules-grid hasSizer">
						{isLoading && <div id="loader" />}
							{!isLoading && isEmpty(missionCards) && (
										<h2>
											{' '}
											Active Mission Pools are empty{' '}
											<svg width="32" height="31" viewBox="0 0 32 31" fill="#fece02">
												<path d="M15.637 17.996 4.69 12.188l-3.078 3.718c3.883 2.07 7.766 4.145 11.653 6.219Zm.847-11.82L5.5 11.68l10.707 5.68 10.73-5.692-6.152-3.25ZM4.137 11.562.102 7.704a.418.418 0 0 1 .039-.586.371.371 0 0 1 .078-.058L12.489.05a.408.408 0 0 1 .546.16l3.445 4.793L18.777.66a.41.41 0 0 1 .555-.176l12.445 6.649c.04.02.07.043.102.074a.417.417 0 0 1-.004.586l-3.688 3.66 3.57 4.313a.422.422 0 0 1-.05.586.642.642 0 0 1-.086.054l-3.203 1.711v6.418c0 .172-.105.32-.258.383l-11.59 5.941a.415.415 0 0 1-.36.133.416.416 0 0 1-.312-.226L4.285 24.902a.42.42 0 0 1-.226-.37v-6.384L.793 16.406a.415.415 0 0 1-.133-.64Zm11.707-6.019L12.527.969 1.06 7.55l3.75 3.523Zm1.277.004 3.844 2.031 6.531 3.399 3.402-3.375-11.59-6.192Zm10.606 6.64-10.946 5.81 2.371 4.124 11.653-6.219Zm0 0" />
											</svg>
										</h2>
									)}
						{missionCards.map(missionPoolProps => (
							<MissionCard key={missionPoolProps.id} {...missionPoolProps} />
						))							
						}
						<div className="module module-thumb filler" />
					</div>
				</div>
			</section>
			{joyrideStatus !== 'ready' && (
				<Joyride
					run={!isLoading}
					continuous
					showSkipButton
					steps={joyrideSteps}
					callback={handleJoyrideCallback}
					styles={joyrideStepsStyleOptions}
					locale={{ last: 'End tour' }}
				/>
			)}
		</Layout>
	);
};
