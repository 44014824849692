import axios from 'axios';
import { gsap } from 'gsap';
import useAsyncEffect from 'use-async-effect';
import { useCallback, useState } from 'react';
import { useEventListener, useLockedBody } from 'usehooks-ts';

import { Layout } from 'components/Layout';
import { NftCard } from 'components/NftCard';
import { NftModal } from 'components/NftModal';

import { baseURL } from 'constants';

const fetchFleetStats = async () => {
	const response = await axios.get(`${baseURL}/v1/dapp/fleet/stats`);

	return response.data;
};

export const WarChest = () => {
	const [nfts, setNfts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [nftModalProps, setNftModalProps] = useState(null);
	const [isNftModalOpen, setIsNftModalOpen] = useState(false);

	const handleNftButtonClick = useCallback(props => {
		setNftModalProps(props);
		setIsNftModalOpen(true);
		gsap.to('#nft', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseNftModal = useCallback(() => {
		gsap.to('#nft', { duration: 0.5, opacity: 0, display: 'none' });
		setIsNftModalOpen(false);
	}, []);

	useLockedBody(isNftModalOpen);
	useEventListener('keyup', event => {
		if (event.key === 'Escape') {
			handleCloseNftModal();
		}
	});

	useAsyncEffect(async () => {
		const fleetStats = await fetchFleetStats();

		setNfts(fleetStats.ships.filter(nft => nft.enlisted));
		setIsLoading(false);
	}, []);

	return (
		<>
			<Layout title="WarChest">
				<section id="war-chest">
					<div className="contentContainer">
						<h2 className="headline-mobile mob">War Chest</h2>
						<div className="modules-grid hasSizer">
							{isLoading ? (
								<div id="loader" />
							) : (
								nfts.map((nft, index) => <NftCard key={index} onClick={handleNftButtonClick} hasViewNftButton {...nft} />)
							)}
							<div className="module module-thumb filler" />
						</div>
					</div>
				</section>
			</Layout>
			<NftModal {...nftModalProps} onClose={handleCloseNftModal} isNftModalOpen={isNftModalOpen} />
		</>
	);
};
