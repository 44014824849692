import { ethers } from 'ethers';
import { PublicKey } from '@solana/web3.js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { solanaEnvSig } from 'api/solana';
import { macroToMicro, microToMacro } from 'utils';

const ethContractAddress = '0x7a45Fa52f8Fe7E01b8419dB1F51f19d2379E5500';

const ethContractABI = [
	{
		inputs: [
			{
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
			{
				internalType: 'string',
				name: 'symbol',
				type: 'string',
			},
			{
				internalType: 'uint256',
				name: 'feeAmount',
				type: 'uint256',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'owner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Approval',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Transfer',
		type: 'event',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'owner',
				type: 'address',
			},
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
		],
		name: 'allowance',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'approve',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'subtractedValue',
				type: 'uint256',
			},
		],
		name: 'decreaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'addedValue',
				type: 'uint256',
			},
		],
		name: 'increaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'name',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [],
		name: 'symbol',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'transfer',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'sender',
				type: 'address',
			},
			{
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'transferFrom',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'mint',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'solanaAccount',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'teleport',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'teleportations',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'solanaAccount',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'amount',
						type: 'uint256',
					},
				],
				internalType: 'struct EArcade.Portal[12]',
				name: '',
				type: 'tuple[12]',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [],
		name: 'isPortalAvailable',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'iBegin',
				type: 'uint256',
			},
			{
				internalType: 'uint256',
				name: 'iEnd',
				type: 'uint256',
			},
		],
		name: 'clearPortals',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'newFee',
				type: 'uint256',
			},
		],
		name: 'changeFee',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'feeValue',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256',
			},
		],
		stateMutability: 'view',
		type: 'function',
		constant: true,
	},
	{
		inputs: [],
		name: 'decimals',
		outputs: [
			{
				internalType: 'uint8',
				name: '',
				type: 'uint8',
			},
		],
		stateMutability: 'pure',
		type: 'function',
		constant: true,
	},
];

const bridgeAta = '8uYPD9259sQjY9awGSsuzYieRx31ke9HkzQ8YX2HNWF6';
const bridgeData = 'DfFyPPBJ15QgDL8uufrNu18emVLB3BJSsWU1RU2SXbMm';

export const getEArcadeBalance = async () => {
	const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = web3Provider.getSigner();
	const abiContract = new ethers.Contract(ethContractAddress, JSON.stringify(ethContractABI), signer);
	const balance = await abiContract.balanceOf(web3Provider.provider.selectedAddress);

	console.log({ web3Provider, abiContract, address: web3Provider.provider.selectedAddress, balance, userBalance: macroToMicro(balance) });

	return macroToMicro(balance);
};

export const isPortalAvailable = async program => {
	const data = await program.account.data.fetch(bridgeData);
	const portals = data.portals;

	for (let i = 0; i < portals.length; i++) {
		if (portals[i].amount === 0) return true;
	}
	return false;
};

export const transferEArcadeToSolana = async (UIAmount, wallet) => {
	try {
		const amount = microToMacro(Number(UIAmount));
		const { program } = solanaEnvSig(wallet);

		console.log({ UIAmount, wallet });

		const parsedAccountInfo = await program.provider.connection.getParsedAccountInfo(new PublicKey(bridgeAta));
		const mintKey = new PublicKey(parsedAccountInfo.value.data.parsed.info.mint);
		const userAta = await Token.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, mintKey, wallet.publicKey, true);

		console.log({ parsedAccountInfo, mintKey, userAta });

		const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = web3Provider.getSigner();
		const abiContract = new ethers.Contract(ethContractAddress, JSON.stringify(ethContractABI), signer);

		const transferFee = await abiContract.feeValue();
		if (amount <= transferFee.toNumber()) {
			throw Error('Amount smaller than transfer fee!');
		}

		const balance = await getEArcadeBalance();
		if (amount > microToMacro(balance)) {
			throw Error('Insufficient eArcade balance!');
		}

		const isAvailable = await abiContract.isPortalAvailable();
		// const isAvailable = await isPortalAvailable(program);
		if (!isAvailable) {
			throw Error('Portal is not available. Please try again later!');
		}

		console.log({ amount, transferFee: transferFee.toNumber(), balance: microToMacro(balance), isAvailable, abiContract });

		const solAccount = userAta.toBytes();

		const tx = await abiContract.teleport(solAccount, amount);

		return tx;
	} catch (error) {
		throw error;
	}
};
