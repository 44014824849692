import { gsap } from 'gsap';
import { useCallback, useContext, useState, useMemo } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useEventListener, useLockedBody } from 'usehooks-ts';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { Layout } from 'components/Layout';

import Select, { components } from 'react-select';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { initializeSwapping } from 'api/swapping';
import { solanaEnvSig, solanaEnvSAS } from 'api/solana';
import { MissionsContext } from 'contexts/Missions';
import { MissionCard } from 'components/MissionCard';
import { RequestMissionModal } from 'components/RequestMissionModal';
import { DeleteMissionModal } from 'components/DeleteMissionModal';
import { RewardMissionModal } from 'components/RewardMissionModal';
import { ApproveMissionModal } from 'components/ApproveMissionModal';

const getGamePhase = (gamePhase) =>{
	switch(gamePhase){
		case 0:
			return "Awaiting Approval";
		case 1:
			return "Approved & Live"
		case 2:
			return "Funding Round Complete - Pending Enddate"
		case 3:
			return "Closed - Awaiting User Reward Redemption"
		default: 
			return "Select Type"
	}
}

export const Admin = () => {
	const { missionPools, isLoading } = useContext(MissionsContext);
	const [selectedMissionProps, setSelectedMissionProps] = useState(null);
	const [isDeleteMissionModalOpen, setIsDeleteMissionModalOpen] = useState(false);
	const [isRewardMissionModalOpen, setIsRewardMissionModalOpen] = useState(false);
	const [isRequestMissionModalOpen, setIsRequestMissionModalOpen] = useState(false);
	const [isApproveMissionModalOpen, setIsApproveMissionModalOpen] = useState(false);
	const [selectedGamePhase, setSelectedGamePhase] = useState({ value: 'all', label: 'All' });
	const wallet = useAnchorWallet();

	// Admin Dropdown
	const handleChange = useCallback(nextSelectedGameType => {
		setSelectedGamePhase(nextSelectedGameType);
	}, []);

	const missionCards = useMemo(
		() => (selectedGamePhase.value === 'all' ? missionPools : missionPools.filter(({ gamePhase }) => (gamePhase === selectedGamePhase.value))),
		[missionPools, selectedGamePhase.value]
	);

	const gameTypeOptions = useMemo(
		() =>
			uniqBy(
				missionPools.map(({ gamePhase, bcGamePhase }) => ({ value: bcGamePhase, label: getGamePhase(bcGamePhase) })),
				'value'
			),
		[missionPools]
	);

	// Approve Mission
	const handleApproveMissionButtonClick = useCallback(props => {
		setSelectedMissionProps(props);
		setIsApproveMissionModalOpen(true);
		gsap.to('#approve-mission', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseApproveMissionModal = useCallback(() => {
		gsap.to('#approve-mission', { duration: 0.5, opacity: 0, display: 'none' });
		setIsApproveMissionModalOpen(false);
	}, []);
	// End Approve Mission

	const handleInitializeSwappingButtonClick = useCallback(() => {
		return 0;
		const { program, xMint, mint } = solanaEnvSAS(wallet);

		initializeSwapping({ feePercentage: 3, feeMax: 30, arcadeMint: mint, xArcadeMint: xMint, ownerPub: wallet.publicKey, program: program })
			.then(resp => console.log('Data: %s, Ata: %s', resp.escrowData.toString(), resp.escrowAta.toString()))
			.catch(error => console.error(error));
	}, [wallet]);

	// Delete Mission
	const handleDeleteMissionButtonClick = useCallback(props => {
		setSelectedMissionProps(props);
		setIsDeleteMissionModalOpen(true);
		gsap.to('#delete-mission', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseDeleteMissionModal = useCallback(() => {
		gsap.to('#delete-mission', { duration: 0.5, opacity: 0, display: 'none' });
		setIsDeleteMissionModalOpen(false);
	}, []);
	// End Delete Mission

	// Reward Mission
	const handleRewardMissionButtonClick = useCallback(props => {
		setSelectedMissionProps(props);
		setIsRewardMissionModalOpen(true);
		gsap.to('#reward-mission', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseRewardMissionModal = useCallback(() => {
		gsap.to('#reward-mission', { duration: 0.5, opacity: 0, display: 'none' });
		setIsRewardMissionModalOpen(false);
	}, []);
	// End Reward Mission

	// Request Mission
	const handleRequestMissionButtonClick = useCallback(props => {
		setIsRequestMissionModalOpen(true);
		gsap.to('#request-mission', { duration: 0.5, opacity: 1, display: 'block' });
	}, []);

	const handleCloseRequestMissionModal = useCallback(() => {
		gsap.to('#request-mission', { duration: 0.5, opacity: 0, display: 'none' });
		setIsRequestMissionModalOpen(false);
	}, []);
	// End Request Mission

	useLockedBody(isApproveMissionModalOpen || isDeleteMissionModalOpen || isRequestMissionModalOpen);
	useEventListener('keyup', event => {
		if (event.key === 'Escape') {
			handleCloseApproveMissionModal();
			handleCloseDeleteMissionModal();
			handleCloseRewardMissionModal();
			handleCloseRequestMissionModal();
		}
	});

	const [AdminButtons, setHasAdminButtons] = useState(false);
	const [MPOButtons, setHasMPOButtons] = useState(false);

	useAsyncEffect(async () => {
		const { trustedOwnerPub } = solanaEnvSig(wallet);
		let trusted = trustedOwnerPub.filter((pubkey)=> pubkey === wallet.publicKey );
		if (trusted) setHasAdminButtons(true);
		else setHasMPOButtons(true);
		
	}, [wallet]);

	return (
		<>
			<Layout title="Admin">
				<section id="mission-pools">
					<div className="contentContainer">
						<h2 className="headline-mobile mob">Active Mission Pools</h2>
						<div className="filter-wrapper">
							{ AdminButtons && (
								<button className="cta-btn large" >
									<span>Add Game</span>
								</button>
							)}
							<button className="cta-btn large" onClick={handleRequestMissionButtonClick}>
								<span>Request Mission</span>
							</button>
							{ AdminButtons && (
							<button className="cta-btn large" onClick={handleInitializeSwappingButtonClick}>
								<span>Initialize Swapping</span>
							</button>
							)}
						</div>
						<h2 className="headline-mobile mob">Active Mission Pools
								{!missionCards && isEmpty(missionCards) && (
									<>
										{' '}
										are empty{' '}
										<svg width="32" height="31" viewBox="0 0 32 31" fill="#fece02">
											<path d="M15.637 17.996 4.69 12.188l-3.078 3.718c3.883 2.07 7.766 4.145 11.653 6.219Zm.847-11.82L5.5 11.68l10.707 5.68 10.73-5.692-6.152-3.25ZM4.137 11.562.102 7.704a.418.418 0 0 1 .039-.586.371.371 0 0 1 .078-.058L12.489.05a.408.408 0 0 1 .546.16l3.445 4.793L18.777.66a.41.41 0 0 1 .555-.176l12.445 6.649c.04.02.07.043.102.074a.417.417 0 0 1-.004.586l-3.688 3.66 3.57 4.313a.422.422 0 0 1-.05.586.642.642 0 0 1-.086.054l-3.203 1.711v6.418c0 .172-.105.32-.258.383l-11.59 5.941a.415.415 0 0 1-.36.133.416.416 0 0 1-.312-.226L4.285 24.902a.42.42 0 0 1-.226-.37v-6.384L.793 16.406a.415.415 0 0 1-.133-.64Zm11.707-6.019L12.527.969 1.06 7.55l3.75 3.523Zm1.277.004 3.844 2.031 6.531 3.399 3.402-3.375-11.59-6.192Zm10.606 6.64-10.946 5.81 2.371 4.124 11.653-6.219Zm0 0" />
										</svg>
									</>
								)}
							</h2>
							{!isLoading && (
						<div className="filter-wrapper">
							<Select
								isSearchable={false}
								className="react-select"
								value={selectedGamePhase}
								onChange={handleChange}
								classNamePrefix="react-select"
								options={[{ value: 'all', label: 'All' }, ...gameTypeOptions]}
								components={{
									IndicatorSeparator: () => null,
									DropdownIndicator: props => (
										<components.DropdownIndicator {...props}>
											<div className="icon-arrow" style={{ color: props.selectProps.menuIsOpen ? '#fece02' : '#e019f9' }} />
										</components.DropdownIndicator>
									),
								}}
							/>
						</div>
					)}	
						<div className="modules-grid hasSizer">
							{isLoading && <div id="loader" />}
							{missionCards.map(missionPoolProps => (
								<MissionCard
									key={missionPoolProps.id}
									hasAdminButtons
									onDeleteButtonClick={handleDeleteMissionButtonClick}
									onRewardButtonClick={handleRewardMissionButtonClick}
									onApproveButtonClick={handleApproveMissionButtonClick}
									{...missionPoolProps}
								/>
							))}
							<div className="module module-thumb filler" />
						</div>
					</div>
				</section>
			</Layout>
			<ApproveMissionModal isOpen={isApproveMissionModalOpen} onClose={handleCloseApproveMissionModal} missionCardProps={selectedMissionProps} />
			<RequestMissionModal isOpen={isRequestMissionModalOpen} onClose={handleCloseRequestMissionModal} />
			<DeleteMissionModal onClose={handleCloseDeleteMissionModal} missionCardProps={selectedMissionProps} />
			<RewardMissionModal
				isOpen={isRewardMissionModalOpen}
				onClose={handleCloseRewardMissionModal}
				missionCardProps={selectedMissionProps}
			/>
		</>
	);
};
