import axios from 'axios';
import toast from 'react-hot-toast';
import { PublicKey } from '@solana/web3.js';
import useAsyncEffect from 'use-async-effect';
import { useWallet } from '@solana/wallet-adapter-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { Token, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { baseURL } from 'constants';
import { macroToMicro } from 'utils';
import { solanaEnvSig } from 'api/solana';
import { MissionsContext } from 'contexts/Missions';
import { getReclaimableAmounts } from 'api/missions';

export const BalancesContext = createContext({ account: {}, reward: {} });

export const fetchAccountBalance = async ({ wallet, walletPublicKey }) => {
	const { program, mint, xMint } = solanaEnvSig(wallet);

	let xArcadeBalance, arcadeBalance;
	let err = 0;
	try {
		const associatedTokenAddress = await Token.getAssociatedTokenAddress(
			ASSOCIATED_TOKEN_PROGRAM_ID,
			TOKEN_PROGRAM_ID,
			mint,
			walletPublicKey,
			true
		);

		const associatedAccountInfo = await program.provider.connection.getParsedAccountInfo(new PublicKey(associatedTokenAddress));

		arcadeBalance = associatedAccountInfo.value.data.parsed.info.tokenAmount.uiAmount;
	} catch (error) {
		err += 1;
		//console.log('There is no Arcade TokenAccount');
		arcadeBalance = 0;
	}
	try {
		const xAssociatedTokenAddress = await Token.getAssociatedTokenAddress(
			ASSOCIATED_TOKEN_PROGRAM_ID,
			TOKEN_PROGRAM_ID,
			xMint,
			walletPublicKey,
			true
		);

		const xAssociatedAccountInfo = await program.provider.connection.getParsedAccountInfo(new PublicKey(xAssociatedTokenAddress));
		xArcadeBalance = xAssociatedAccountInfo.value.data.parsed.info.tokenAmount.uiAmount;
	} catch (error) {
		err += 2;
		console.log('There is no xArcade TokenAccount');
		xArcadeBalance = 0;
	}
	// TODO: This is a check for tokens and alerts the user. Disabled for PA
	/*if (err === 1)
		toast.error("You do not yet have Arcade Tokens. Use 'Convert Tokens' to swap your xArcade for Arcade when you're ready to leave!");
	else if (err === 2) toast.error("You do not currently have xArcade Tokens. Use 'Convert Tokens' to swap for xArcade");
	*/
	return { arcadeBalance, xArcadeBalance };
};

const fetchTotalRewardsBalance = async walletPublicKey => {
	const response = await axios.get(`${baseURL}/v1/dapp/rewards/${walletPublicKey}`);
	if (response.data.length === 0) return 0;
	return response.data;
};

const defaultAccountBalance = { arcade: 0, xArcade: 0, arcadeActual: 0, xArcadeActual: 0 };
const defaultRewardBalance = { total: 0, pending: 0, reclaimable: 0 };

export const BalancesContextProvider = ({ children }) => {
	const { missionPools } = useContext(MissionsContext);
	const [shouldRefresh, setShouldRefresh] = useState(false);
	const { publicKey: walletPublicKey, wallet } = useWallet();
	const [rewardBalance, setRewardBalance] = useState(defaultRewardBalance);
	const [accountBalance, setAccountBalance] = useState(defaultAccountBalance);
	const [isLoadingRewardBalance, setIsLoadingRewardBalance] = useState(false);
	const [isLoadingAccountBalance, setIsLoadingAccountBalance] = useState(false);

	// Retrieving account balance
	useAsyncEffect(async () => {
		setIsLoadingAccountBalance(true);
		try {
			if (walletPublicKey) {
				const { arcadeBalance, xArcadeBalance } = await fetchAccountBalance({ wallet, walletPublicKey });
				setAccountBalance({ arcade: parseFloat(arcadeBalance.toFixed(3)), xArcade: parseFloat(xArcadeBalance.toFixed(3)), arcadeActual: parseFloat(arcadeBalance), xArcadeActual: parseFloat(xArcadeBalance)});
			}
		} catch (error) {
			setAccountBalance(defaultAccountBalance);
			toast.error(`There was an error while fetching account balance: ${error.message}`);
			console.error(error);
			throw error;
		} finally {
			setIsLoadingAccountBalance(false);
		}
	}, [wallet, walletPublicKey, shouldRefresh]);

	// Retrieving rewards information
	useAsyncEffect(async () => {
		try {
			setIsLoadingRewardBalance(true);
			if (walletPublicKey && missionPools.length) {
				let totalVestings = 0;
				let totalRewardsPending = 0;
				const totalRewardsBalance = await fetchTotalRewardsBalance(walletPublicKey);
				const blockchainMissions = missionPools.map(({ bcMission }) => bcMission);
				const reclaimableAmounts = await getReclaimableAmounts({
					userBlockChainMissions: blockchainMissions,
					wallet,
					walletPublicKey,
				});
				blockchainMissions.forEach((mission, index) => {
					if(mission !== undefined){
						totalRewardsPending += mission.bcGamePhase === 3 ? reclaimableAmounts[index].reward : 0;
						totalVestings += mission.bcGamePhase === 3 ? reclaimableAmounts[index].vesting : 0;
					}
				});

				const reclaimableAmount = totalRewardsPending + totalVestings;

				setRewardBalance({
					total: parseFloat(macroToMicro(totalRewardsBalance).toFixed(3)),
					pending: parseFloat(macroToMicro(totalRewardsPending).toFixed(3)),
					reclaimable: parseFloat(macroToMicro(reclaimableAmount).toFixed(3)),
				});
			}
		} catch (error) {
			setRewardBalance(defaultRewardBalance);
			toast.error(`There was an error while fetching reward balance: ${error.message}`);
			console.error(error);
			throw error;
		} finally {
			setIsLoadingRewardBalance(false);
		}
	}, [wallet, walletPublicKey, shouldRefresh, missionPools]);

	useEffect(() => {
		if (!wallet) {
			setRewardBalance(defaultRewardBalance);
			setAccountBalance(defaultAccountBalance);
		}
	}, [wallet]);

	return (
		<BalancesContext.Provider
			value={{ account: accountBalance, reward: rewardBalance, setShouldRefresh, isLoadingRewardBalance, isLoadingAccountBalance }}
		>
			{children}
		</BalancesContext.Provider>
	);
};
