import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';
import { useCallback, useEffect, useState } from 'react';

import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

export const AtaOptionsModal = ({ onClose }) => {
	const [formValues, setFormValues] = useState({
		associatedTokenAddress: '',
		xAssociatedTokenAddress: '',
		shouldShowAtaOptions: false,
	});

	const [ata, setAta] = useLocalStorage('ata', null);
	const [xAta, setXAta] = useLocalStorage('xAta', null);
	const { associatedTokenAddress, xAssociatedTokenAddress } = useAssociatedTokenAddress();
	const [shouldShowAtaOptions, setShouldShowAtaOptions] = useLocalStorage('shouldShowAtaOptions', false);

	useEffect(() => {
		setFormValues({
			associatedTokenAddress: ata ?? associatedTokenAddress,
			xAssociatedTokenAddress: xAta ?? xAssociatedTokenAddress,
			shouldShowAtaOptions,
		});
	}, [associatedTokenAddress, ata, shouldShowAtaOptions, xAssociatedTokenAddress, xAta]);

	const handleAtaInputChange = useCallback(
		event =>
			setFormValues(associatedTokenAddresses => ({
				...associatedTokenAddresses,
				associatedTokenAddress: event.target.value,
			})),
		[]
	);
	const handleXAtaInputChange = useCallback(
		event =>
			setFormValues(associatedTokenAddresses => ({
				...associatedTokenAddresses,
				xAssociatedTokenAddress: event.target.value,
			})),
		[]
	);
	const handleCheckboxChange = useCallback(
		() =>
			setFormValues(associatedTokenAddresses => ({
				...associatedTokenAddresses,
				shouldShowAtaOptions: !associatedTokenAddresses.shouldShowAtaOptions,
			})),
		[]
	);

	const handleSubmit = useCallback(
		event => {
			event.preventDefault();
			setAta(formValues.associatedTokenAddress);
			setXAta(formValues.xAssociatedTokenAddress);
			setShouldShowAtaOptions(formValues.shouldShowAtaOptions);
			toast.success('Settings saved successfully!');
			onClose();
		},
		[formValues, setAta, setShouldShowAtaOptions, setXAta, onClose]
	);

	return (
		<div id="ata-options" className="overlay deposit global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={onClose}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					{/* FORM */}
					<form id="ata-options-form" className="row main spaced" onSubmit={handleSubmit}>
						<div className="col left">
							<div className="modal-title">
								<div className="row title">
									<h3>Default ATA Options</h3>
								</div>
								<div className="form-field">
									<label htmlFor="ata-input">Arcade ATA</label>
									<input
										type="text"
										name="title"
										id="ata-input"
										placeholder="Enter here"
										required
										onChange={handleAtaInputChange}
										value={formValues.associatedTokenAddress ?? ''}
									/>
								</div>
								<div className="form-field">
									<label htmlFor="x-ata-input">xArcade ATA</label>
									<input
										type="text"
										name="title"
										id="x-ata-input"
										placeholder="Enter here"
										required
										onChange={handleXAtaInputChange}
										value={formValues.xAssociatedTokenAddress ?? ''}
									/>
								</div>
								<div className="checkbox-wrapper">
									<input id="show-ata" type="checkbox" onChange={handleCheckboxChange} checked={formValues.shouldShowAtaOptions} />
									<label htmlFor="show-ata">Show ATA Override in dApp</label>
								</div>
								<div className="row cta">
									<button className="cta-btn large submit">
										<span>Save Changes</span>
									</button>
								</div>
							</div>
						</div>
					</form>
					{/* END FORM */}
				</div>
			</div>
		</div>
	);
};
