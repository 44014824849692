import { Connection, PublicKey } from '@solana/web3.js';
import { Program, Provider } from '@project-serum/anchor';

import { SwapPlan } from 'api/swapping';
import { isInDevelopment } from 'constants';

const idlPool = {
	version: '0.1.0',
	name: 'mission_pool',
	instructions: [
		{
			name: 'createMission',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: true,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'tokenType',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'systemProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'rent',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'bump',
					type: 'u8',
				},
				{
					name: 'endDate',
					type: 'i64',
				},
				{
					name: 'poolTarget',
					type: 'u64',
				},
				{
					name: 'feePercent',
					type: 'u8',
				},
				{
					name: 'maxVestingPercent',
					type: 'u8',
				},
				{
					name: 'gameType',
					type: 'string',
				},
			],
		},
		{
			name: 'investTokens',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'investor',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'investorAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'amount',
					type: 'u64',
				},
			],
		},
		{
			name: 'withdrawVesting',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'investor',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'investorAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'index',
					type: 'u64',
				},
			],
		},
		{
			name: 'endTask',
			accounts: [
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'ownerAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'returnAmount',
					type: 'u64',
				},
				{
					name: 'isSuccess',
					type: 'bool',
				},
			],
		},
		{
			name: 'getVesting',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'investor',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'investorAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'index',
					type: 'u64',
				},
			],
		},
		{
			name: 'returnVesting',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'investorAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'index',
					type: 'u64',
				},
			],
		},
		{
			name: 'closeMission',
			accounts: [
				{
					name: 'data',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'mission',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'missionAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'ownerAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [],
		},
	],
	accounts: [
		{
			name: 'Data',
			type: {
				kind: 'struct',
				fields: [
					{
						name: 'events',
						type: {
							array: [
								{
									defined: 'Investment',
								},
								360,
							],
						},
					},
				],
			},
		},
		{
			name: 'Mission',
			type: {
				kind: 'struct',
				fields: [
					{
						name: 'endDate',
						type: 'i64',
					},
					{
						name: 'feePercent',
						type: 'u8',
					},
					{
						name: 'maxVestingPercent',
						type: 'u8',
					},
					{
						name: 'bump',
						type: 'u8',
					},
					{
						name: 'phase',
						type: 'u8',
					},
					{
						name: 'owner',
						type: 'publicKey',
					},
					{
						name: 'dataAccount',
						type: 'publicKey',
					},
					{
						name: 'poolTarget',
						type: 'u64',
					},
					{
						name: 'reward',
						type: 'u64',
					},
					{
						name: 'dataIndex',
						type: 'u64',
					},
					{
						name: 'gameType',
						type: 'string',
					},
				],
			},
		},
	],
	types: [
		{
			name: 'Investment',
			type: {
				kind: 'struct',
				fields: [
					{
						name: 'investor',
						type: 'publicKey',
					},
					{
						name: 'investorAta',
						type: 'publicKey',
					},
					{
						name: 'amount',
						type: 'u64',
					},
				],
			},
		},
		{
			name: 'RpcInvestment',
			type: {
				kind: 'struct',
				fields: [
					{
						name: 'investor',
						type: 'publicKey',
					},
					{
						name: 'investorAta',
						type: 'publicKey',
					},
					{
						name: 'amount',
						type: 'u64',
					},
				],
			},
		},
	],
	errors: [
		{
			code: 6000,
			name: 'NotImplemented',
			msg: 'Sorry. Not yet implemented!',
		},
		{
			code: 6001,
			name: 'NullInvestment',
			msg: 'Investment amount must be greater than zero!',
		},
		{
			code: 6002,
			name: 'TooLateToInvest',
			msg: 'Too late to invest. The mission is already pending!',
		},
		{
			code: 6003,
			name: 'TooLateToWithdraw',
			msg: 'Too late to withdraw. The mission is closed!',
		},
		{
			code: 6004,
			name: 'EndTaskInWrongPhase',
			msg: 'The mission must be pending before calling end_task()!',
		},
		{
			code: 6005,
			name: 'TokensAlreadyWithdrew',
			msg: 'The tokens are already withdrawn!',
		},
		{
			code: 6006,
			name: 'IncorrectInvestmentIndex',
			msg: 'Investment not found at the index. Please try again with a correct index!',
		},
		{
			code: 6007,
			name: 'WrongInvestor',
			msg: 'The investor does not match the address on data!',
		},
		{
			code: 6008,
			name: 'WrongInvestorAta',
			msg: 'The token account does not match the address on data!',
		},
		{
			code: 6009,
			name: 'OutOfSpace',
			msg: 'The maximum number of investments has been reached!',
		},
		{
			code: 6010,
			name: 'TaskNotClosed',
			msg: 'The mission must first be closed!',
		},
		{
			code: 6011,
			name: 'AmountTooBig',
			msg: 'The investment amount should be smaller!',
		},
		{
			code: 6012,
			name: 'InvalidFeePercentage',
			msg: 'The fee percentage is too large!',
		},
		{
			code: 6013,
			name: 'InvalidPercentage',
			msg: 'Invalid percentage!',
		},
		{
			code: 6014,
			name: 'CantCloseMission',
			msg: "The mission can't be closed because there are pending vestings!",
		},
		{
			code: 6015,
			name: 'TooEarlyToEnd',
			msg: 'The current time needs to greater than the end-date!',
		},
		{
			code: 6016,
			name: 'ZeroRewardWhenSuccess',
			msg: 'Cannot zero reward when a mission is successful!',
		},
		{
			code: 6017,
			name: 'CantRewardWhenFailed',
			msg: 'Cannot give a reward when a mission failed!',
		},
		{
			code: 6018,
			name: 'CantReturnWhenNotOpen',
			msg: 'Cannot return to user when not in open phase!',
		},
		{
			code: 6019,
			name: 'FutureCantBePast',
			msg: 'The end-date cannot be set in the past!',
		},
	],
	metadata: {
		address: isInDevelopment ? 'MP1MeVYcCp5o53aeQLijJeHyLEDueZKvsVA8eMFrkPi' : 'MPV1D8qY8VXN7EpVkbreD6M6eHko9AKxmCJuCntkXS7', // MissionPool's PubKey
	},
};

const idlSAS = {
	version: '0.1.0',
	name: 'token_swapping',
	instructions: [
		{
			name: 'initializeSwapping',
			accounts: [
				{
					name: 'xArcadeMint',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'escrowData',
					isMut: true,
					isSigner: true,
				},
				{
					name: 'escrowAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'arcadeMint',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'systemProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'rent',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'bump',
					type: 'u8',
				},
				{
					name: 'feePercent',
					type: 'u8',
				},
				{
					name: 'feeMax',
					type: 'u64',
				},
			],
		},
		{
			name: 'changeFee',
			accounts: [
				{
					name: 'escrowData',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
			],
			args: [
				{
					name: 'feePercent',
					type: 'u8',
				},
				{
					name: 'feeMax',
					type: 'u64',
				},
			],
		},
		{
			name: 'closeSwapping',
			accounts: [
				{
					name: 'escrowData',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'escrowAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'ownerAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [],
		},
		{
			name: 'recupFees',
			accounts: [
				{
					name: 'escrowData',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'escrowAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'owner',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'ownerAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [],
		},
		{
			name: 'createXArcadeAccount',
			accounts: [
				{
					name: 'xArcadeMint',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'escrowData',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'swapper',
					isMut: true,
					isSigner: true,
				},
				{
					name: 'swapperXArcade',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'associatedTokenProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'systemProgram',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'rent',
					isMut: false,
					isSigner: false,
				},
			],
			args: [],
		},
		{
			name: 'arcadeToXArcade',
			accounts: [
				{
					name: 'xArcadeMint',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'escrowData',
					isMut: false,
					isSigner: false,
				},
				{
					name: 'escrowAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'swapper',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'swapperArcade',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'swapperXArcade',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'arcadeAmount',
					type: 'u64',
				},
			],
		},
		{
			name: 'xArcadeToArcade',
			accounts: [
				{
					name: 'xArcadeMint',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'escrowData',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'escrowAta',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'swapper',
					isMut: false,
					isSigner: true,
				},
				{
					name: 'swapperArcade',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'swapperXArcade',
					isMut: true,
					isSigner: false,
				},
				{
					name: 'tokenProgram',
					isMut: false,
					isSigner: false,
				},
			],
			args: [
				{
					name: 'xArcadeAmount',
					type: 'u64',
				},
			],
		},
	],
	accounts: [
		{
			name: 'EscrowData',
			type: {
				kind: 'struct',
				fields: [
					{
						name: 'bump',
						type: 'u8',
					},
					{
						name: 'feePercent',
						type: 'u8',
					},
					{
						name: 'feeMax',
						type: 'u64',
					},
					{
						name: 'feeSum',
						type: 'u64',
					},
					{
						name: 'owner',
						type: 'publicKey',
					},
					{
						name: 'xArcadeMint',
						type: 'publicKey',
					},
				],
			},
		},
	],
	errors: [
		{
			code: 6000,
			name: 'NotImplemented',
			msg: 'Not yet implemented!',
		},
		{
			code: 6001,
			name: 'NullContribution',
			msg: 'The contribution amount must be greater than zero!',
		},
		{
			code: 6002,
			name: 'NullAmount',
			msg: 'The amount must be greater than zero!',
		},
		{
			code: 6003,
			name: 'NotEnoughFunds',
			msg: 'The account is unsufficiently funded!',
		},
		{
			code: 6004,
			name: 'InvalidPercentage',
			msg: 'The percentage should be between 0 and 100!',
		},
	],
	metadata: {
		address: isInDevelopment ? 'Ef2SzCwDimGK6U769CqbF7jGed5jo66rnXUKg8Cj7kjF' : 'tSASn42ydm4ikoVwstoj3x2hBFZxNrng9w8gqiK2dng', //SWAP Program's PubKey
	},
};

const xArcadeMintPublicKey58 = isInDevelopment
	? '3vFacN7boAe3GQvWWACz1k8sKfqPvtboJRoKwadJFNfD'
	: 'xARjQBdLR3q4vXh7DwbKVFg4fKJcfHk7aZaJTaByvhM'; //xArcade Token's PubKey
const trustedOwnerPublicKey58 = isInDevelopment
	? ['4dRsTCCMt92uE3d88sY5h3Fmkjm9VUPLtvWsHeE2qU75','saad8rHQzNJkb3sX3jJZR4qChRPvR94uradmDFJQQuV']  //BlockChain Admin's Pubkey for DevNet (add multiple if desired);
  : ['4dRsTCCMt92uE3d88sY5h3Fmkjm9VUPLtvWsHeE2qU75']; //BlockChain Admin's Pubkey for MainNet;
const arcadeMintRawPublicKey58 = isInDevelopment
	? 'DHXvZ9TfaNRKPeptVM7UE6R4G1D16Xx3NdwUgNPGj9Hw'
	: 'ARcAdExwxKEaTMKFjAWL17YespofK3a7poexuBvAPmrN'; //Arcade Token's PubKey
const sasDataPublicKey58 = isInDevelopment ? '7SD7WBFU8YgQr5bbKefTrvGEh6dqB7rpomBMfwqiCb7K' : 'CHANGEME'; //  SwapPlan's DATA pubkey
const sasAtaPublicKey58 = isInDevelopment ? '8e9sGFuqqtGVhN3Tz3MfPcgvEb9kbUZ5pU55wSfZkMx3' : 'CHANGEME'; // SwapPlan's ATA pubkey

const commitment = 'finalized';
const preflightCommitment = 'finalized';

export function solanaEnvSig(wallet) {
	const connection = new Connection(isInDevelopment ? 'https://api.devnet.solana.com' :'https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/'); //Connection('https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/');
	const provider = new Provider(connection, wallet, { preflightCommitment, commitment });
	const programId = new PublicKey(idlPool.metadata.address);
	const program = new Program(idlPool, programId, provider);
	const xMint = new PublicKey(xArcadeMintPublicKey58);
	const mint = new PublicKey(arcadeMintRawPublicKey58);
	const trustedOwnerPub = trustedOwnerPublicKey58.map(pubkeys => new PublicKey(pubkeys));

	return { program, mint, xMint, trustedOwnerPub, programId };
}

export function solanaEnvNoSig() {
	const connection = new Connection(isInDevelopment ? 'https://api.devnet.solana.com' :'https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/'); //Connection('https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/');
	const provider = new Provider(connection, null);
	const programId = new PublicKey(idlPool.metadata.address);
	const programNoSig = new Program(idlPool, programId, provider);
	const trustedOwnerPub = trustedOwnerPublicKey58.map(pubkeys => new PublicKey(pubkeys));

	return { programNoSig, trustedOwnerPub, programId };
}

export function solanaEnvSAS(wallet) {
	const connection = new Connection(isInDevelopment ? 'https://api.devnet.solana.com' :'https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/'); //Connection('https://green-blissful-seed.solana-mainnet.discover.quiknode.pro/73561fbdd9aa8de098fda3b92aae31fda31508bc/');
	const provider = new Provider(connection, wallet, { preflightCommitment, commitment });
	const programId = new PublicKey(idlSAS.metadata.address);
	const program = new Program(idlSAS, programId, provider);
	const xMint = new PublicKey(xArcadeMintPublicKey58);
	const mint = new PublicKey(arcadeMintRawPublicKey58);
	const swapPlan = new SwapPlan(new PublicKey(sasDataPublicKey58), new PublicKey(sasAtaPublicKey58), new PublicKey(xArcadeMintPublicKey58));

	const trustedOwnerPub = trustedOwnerPublicKey58.map(pubkeys => new PublicKey(pubkeys));
	return { program, swapPlan, mint, xMint, trustedOwnerPub, connection };
}
