import isEmpty from 'lodash/isEmpty';

import { Image } from 'components/Image';
import { NftCard } from 'components/NftCard';

export const NftModal = ({ isNftModalOpen, ship, onClose, ...nftCardProps }) => {
	return (
		<div id="nft" className="overlay nft global">
			<div className="blanket" />
			<div className="overlay-wrap">
				{/* bottom gradient for mobile */}
				<div className="bg-gradient mob">
					<div className="bg-glow">
						<Image src="/images/bg-gradient@2x.jpg" className="spacer" alt="" />
					</div>
				</div>
				{/* back button for mobile */}
				<button onClick={onClose} className="back-btn overlay-back hasArrow mob">
					<span className="icon-arrow btn-icon" />
					<span className="txt">Back</span>
				</button>
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose}>
						<span className="icon-cross btn-icon" />
					</button>
					<div className="row main spaced">
						<div className="col left">{!isEmpty(nftCardProps) && <NftCard {...nftCardProps} ship={ship} />}</div>
						<div className="col right">
							<h3>Description</h3>
							<p>{ship?.description}</p>
							{/* NOTE: Hiding this section because it was not implemented yet on BE */}
							{/* <div className="mission-log">
								<h3>Mission Log</h3>
								<div className="log-row">
									<div className="log-col title">Mission Name</div>
									<div className="log-col title">Date</div>
									<div className="log-col title">Pool Size</div>
									<div className="log-col title">Rewards</div>
								</div>
								<div className="log-row">
									<div className="log-col">Resupply Mission</div>
									<div className="log-col">4/20/2022</div>
									<div className="log-col">Funding</div>
									<div className="log-col">0</div>
								</div>
								<div className="log-row">
									<div className="log-col">Exploration Mission</div>
									<div className="log-col">4/13/2022</div>
									<div className="log-col">Finished</div>
									<div className="log-col">25,000 ATLAS</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
