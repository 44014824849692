import truncate from 'lodash/truncate';
import { memo, useCallback, useMemo } from 'react';

import { Image } from 'components/Image';

const PureNftCard = ({ name, enlisted, total_earned_UI, mint, ship, onClick, hasViewNftButton = false }) => {
	const handleNftButtonClick = useCallback(() => {
		onClick({ name, enlisted, total_earned_UI, mint, ship });
	}, [enlisted, mint, name, onClick, ship, total_earned_UI]);

	const mintAddress = useMemo(() => truncate(mint, { length: 8 }) + mint?.substring(mint.length - 5, mint.length), [mint]);

	return (
		<div className="module module-thumb war-chest" style={{ backgroundImage: `url(${ship.image})` }}>
			<div className="mod-logo retina">
				<Image src="/images/logo-staratlas@2x.png" alt="" />
			</div>
			<div className="mod-details">
				<div className="row data">
					<div className="sizeBox">
						<h3>{name}</h3>
						<div className="data-row">
							<p className="title">Enlisted in Missions</p>
							<p>{enlisted}</p>
						</div>
						<div className="data-row">
							<p className="title">Total Earned</p>
							<p>{total_earned_UI}</p>
						</div>
						<div className="data-row">
							<p className="title">Mint</p>
							<p>{mintAddress}</p>
						</div>
					</div>
					{hasViewNftButton && (
						<button className="view-nft cta-btn overlay-btn" onClick={handleNftButtonClick}>
							<span>View NFT</span>
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export const NftCard = memo(PureNftCard);
