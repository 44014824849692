export const adminWalletPublicAddress = [
	'4dRsTCCMt92uE3d88sY5h3Fmkjm9VUPLtvWsHeE2qU75', //Arcade Wallet - See Justin
	'4F8hiBV4vB4U8j6ounaksuT3fHQe2rNLdHMfvxxViiYF', //Arcade Wallet - See Justin
	'saad8rHQzNJkb3sX3jJZR4qChRPvR94uradmDFJQQuV', //Plutus 21 Muhammad saad Wallet - see justin this is for testing purposes
	'EC1CJDN4Vd1Pk9uFYvaxKjJxGXskXSuTFfcBhi1m28xs', // Arcade Wallet - See Justin
];

// NOTE: This boolean switcher is for development purposes. It will replace base URL and some required public keys.
export const isInDevelopment = false;
export const baseURL = isInDevelopment ? 'https://api.arcade2earn.io' : 'https://api.arcade2earn.io';

export const getNavigationItems = isWalletConnected =>
	isWalletConnected
		? [
				// { title: 'Overview', href: '/' },
				{ title: 'Dashboard', href: '/dashboard' },
				{ title: 'Mission Pools', href: '/mission-pools' },
				//{ title: 'War Chest', href: '/war-chest' },
		  ]
		: [{ title: 'Mission Pools', href: '/mission-pools' }];
