import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { solanaEnvSig } from 'api/solana';

export const useAssociatedTokenAddress = () => {
	const wallet = useAnchorWallet();
	const { mint, xMint } = solanaEnvSig(wallet);
	const [associatedTokenAddresses, setAssociatedTokenAddresses] = useState({ associatedTokenAddress: null, xAssociatedTokenAddress: null });

	useAsyncEffect(async () => {
		if (wallet) {
			try {
				const associatedTokenAddress = await Token.getAssociatedTokenAddress(
					ASSOCIATED_TOKEN_PROGRAM_ID,
					TOKEN_PROGRAM_ID,
					mint,
					wallet.publicKey,
					true
				);

				const xAssociatedTokenAddress = await Token.getAssociatedTokenAddress(
					ASSOCIATED_TOKEN_PROGRAM_ID,
					TOKEN_PROGRAM_ID,
					xMint,
					wallet.publicKey,
					true
				);

				setAssociatedTokenAddresses({ associatedTokenAddress, xAssociatedTokenAddress });
			} catch (error) {
				console.error(error);
			}
		}
	}, [wallet]);

	return associatedTokenAddresses;
};
