import { useCallback, useRef, useState } from 'react';

export const Image = ({ src, alt, ...otherProps }) => {
	const ref = useRef(null);
	const [width, setWidth] = useState(0);
	const [isRetina, setIsRetina] = useState(false);

	const handleLoad = useCallback(({ target: img }) => {
		setWidth(img.naturalWidth);
		setIsRetina(ref.current.parentElement.classList.contains('retina'));
	}, []);

	return (
		<img
			ref={ref}
			onLoad={handleLoad}
			src={process.env.PUBLIC_URL + src}
			alt={alt}
			{...(isRetina && {
				style: {
					width: '100%',
					height: 'auto',
					maxWidth: width / 2 + 'px',
				},
			})}
			{...otherProps}
		/>
	);
};
