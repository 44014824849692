import { gsap } from 'gsap';
import Tippy from '@tippyjs/react';
import toast from 'react-hot-toast';
import { useMetaMask } from 'metamask-react';
import { useLocalStorage } from 'usehooks-ts';
import useAsyncEffect from 'use-async-effect';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getEArcadeBalance, transferEArcadeToSolana } from 'api/eth';
import { BalancesContext } from 'contexts/Balances';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

import { Loader } from 'components/Loader';
import { DefaultAtaInput } from 'components/DefaultAtaInput';

export const BridgeModal = ({ isOpen, onClose }) => {
	const wallet = useAnchorWallet();
	const [ata] = useLocalStorage('ata');
	const [eArcadeBalance, setEArcadeBalance] = useState(0);
	const { status, connect, account: metaMaskAccount } = useMetaMask();
	const [shouldShowAtaOptions] = useLocalStorage('shouldShowAtaOptions');
	const { associatedTokenAddress: defaultAssociatedTokenAddress } = useAssociatedTokenAddress();

	const associatedTokenAddress = ata ?? defaultAssociatedTokenAddress;

	const [isLoading, setIsLoading] = useState(false);
	const [taxedAmount, setTaxedAmount] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [transactionId, setTransactionId] = useState('');
	const [arcadeAtaValue, setArcadeAtaValue] = useState('');
	const { account, setShouldRefresh: setShouldRefreshBalance } = useContext(BalancesContext);

	const handleArcadeAtaInputChange = useCallback(value => {
		setArcadeAtaValue(value);
	}, []);

	useAsyncEffect(async () => {
		if (status === 'connected') {
			const eArcadeBalance = await getEArcadeBalance();
			setEArcadeBalance(eArcadeBalance);
		}
	}, [status, isOpen]);

	useEffect(() => {
		setArcadeAtaValue(associatedTokenAddress);
	}, [associatedTokenAddress, isOpen]);

	const handleSwappingInputChange = useCallback(event => {
		// eslint-disable-next-line no-useless-escape
		let value = event.target.value.replace(/[^0-9\.]/g, '');

		if (value.split('.').length > 2) {
			value = value.replace(/\.+$/, '');
		}

		setInputValue(value);
	}, []);

	const handleConnectMetaMaskWallet = useCallback(() => {
		connect().then(accounts => {
			toast.success(`You have successfully connected your metamask wallet. ${accounts[0]}`);
		});
	}, [connect]);

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			if (!arcadeAtaValue) {
				toast.error('You have not inputted a default Arcade Account!');
				return;
			}

			if (!inputValue) {
				toast.error('You have not inputted an amount to convert!');
				return;
			}

			try {
				setIsLoading(true);

				if (wallet) {
					const tx = await transferEArcadeToSolana(inputValue, wallet);
					setTransactionId(tx.hash);
					setShouldRefreshBalance(shouldRefresh => !shouldRefresh);
				}

				toast.success(`You have successfully initiated bridge and sent ${inputValue} eth. `);
				gsap.to('.modal-success-view', { startAt: { opacity: 0, display: 'block' }, duration: 1, opacity: 1 });
			} catch (error) {
				toast.error(`Error during bridge initiation: ${error.message}`);
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		},
		[arcadeAtaValue, setShouldRefreshBalance, inputValue, wallet]
	);

	const handleCloseButtonClick = useCallback(() => {
		onClose();
		setInputValue('');
		setTransactionId('');
		setTaxedAmount('');
	}, [onClose]);

	return (
		<div id="bridge" className="overlay deposit global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={handleCloseButtonClick}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={handleCloseButtonClick}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					<form id="convert-tokens-form" className="row main spaced" onSubmit={handleSubmit}>
						<div className="col left">
							<div className="modal-title">
								<div className="row title">
									<h3>Bridge eArcade to Arcade</h3>
									<p>eArcade Balance: {eArcadeBalance}</p>
								</div>
							</div>
							{status === 'connected' ? (
								<Tippy placement="left" content={`You can disconnect MetaMask wallet from extension.  ${metaMaskAccount}`} zIndex={199999}>
									<div>
										<button type="button" className="cta-btn large delete-button" disabled>
											<span>MetaMask Connected</span>
										</button>
									</div>
								</Tippy>
							) : (
								<button type="button" className="cta-btn large delete-button" onClick={handleConnectMetaMaskWallet}>
									<span>Connect MetaMask Wallet</span>
								</button>
							)}
							<div className="form-field">
								<label htmlFor="title">From eArcade to Arcade</label>
								<input
									type="text"
									name="eArcade"
									id="eArcade"
									placeholder="0"
									required
									onChange={handleSwappingInputChange}
									value={inputValue}
								/>
							</div>
							{shouldShowAtaOptions && (
								<DefaultAtaInput
									id="arcade-ata"
									checkboxLabel="Use default Arcade account"
									inputLabel="Arcade ATA"
									value={associatedTokenAddress}
									onChange={handleArcadeAtaInputChange}
								/>
							)}
							<button
								type="submit"
								className="cta-btn large delete-button"
								disabled={!wallet || inputValue === '' || isLoading || status !== 'connected'}
							>
								{isLoading && <Loader />}
								<span>{isLoading ? 'Please wait...' : 'Initiate Bridge'}</span>
							</button>
							{!wallet && <em className="disabled-disclaimer">Wallet must be connected in order to initiate bridge</em>}
						</div>
					</form>
					<div className="modal-success-view">
						<div className="row thank-you spaced">
							<div className="bg-video">
								<video id="bgvid" autoPlay muted loop playsInline>
									<source src="/video/coins-hand.mp4" type="video/mp4" />
								</video>
							</div>
							<div className="row spaced">
								<div style={{ width: '100%' }}>
									<div className="row title" style={{ borderColor: 'rgb(254 206 5 / 50%)' }}>
										<h3 style={{ color: '#fece05' }}>Success</h3>
									</div>
									<h3 style={{ marginBottom: '30px' }}>You have successfully initiated bridge</h3>
									<p className="allow-break margin-top-10">eArcade balance: {eArcadeBalance - Number(inputValue)}</p>
									<p className="allow-break margin-top-10">Arcade balance: {account.arcade}</p>
									{taxedAmount && <p className="allow-break margin-top-10">Taxed amount: {taxedAmount}</p>}
									<p className="allow-break margin-top-10">Transaction: {transactionId}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
