import Joyride from 'react-joyride';
import axios from 'axios';
import Tippy from '@tippyjs/react';
import toast from 'react-hot-toast';
import isEmpty from 'lodash/isEmpty';
import { useLocalStorage, useOnClickOutside } from 'usehooks-ts';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import React, { useCallback, useContext, useMemo, useEffect, useRef, useState } from 'react';

import { Image } from 'components/Image';
import { Loader } from 'components/Loader';

import { baseURL } from 'constants';
import { getAllUserVestings } from 'api/missions';
import { BalancesContext } from 'contexts/Balances';
import { MissionsContext } from 'contexts/Missions';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';
import { numberWithCommas } from 'utils';

export const AsideWallet = ({ onCloseButtonClick, onBridgeButtonClick, onConvertTokensButtonClick, onShowAtaOptionsButtonClick }) => {
	const asideRef = useRef(null);
	const [xAta] = useLocalStorage('xAta');
	const [isLoading, setIsLoading] = useState(false);
	const [isBalanceHidden, setIsBalanceHidden] = useState(false);
	const [isCurrencyHidden, setIsCurrencyHidden] = useState(false);
	const { missionPools, setShouldRefresh: setShouldRefreshMissions } = useContext(MissionsContext);
	const { account, reward, isLoadingRewardBalance, isLoadingAccountBalance } = useContext(BalancesContext);

	const anchorWallet = useAnchorWallet();
	const { visible: isWalletModalVisible, setVisible } = useWalletModal();
	const { xAssociatedTokenAddress: defaultXAssociatedTokenAddress } = useAssociatedTokenAddress();
	const { wallet, connect, connecting, connected, disconnect, disconnecting } = useWallet();
	const [autoConnect, setAutoConnect] = useLocalStorage('autoconnect', false);
	const [joyrideStatus, setJoyride] = useLocalStorage('joyride', 'started');
	const xAssociatedTokenAddress = xAta ?? defaultXAssociatedTokenAddress;

	const redeemableMissions = useMemo(
		() => missionPools.filter(({ bcGamePhase, currentVesting }) => bcGamePhase === 3 && currentVesting > 0),
		[missionPools]
	);

	useEffect(() => {
		if(wallet){
			const { publicKey } = wallet?.adapter
			//if(publicKey !== null)
				//console.log('connected, we should record metrics: ', publicKey.toString());	
				//TODO here is where I want to do a api call and record metrics
		}	
	}, [wallet]);

	const handleSelectWalletButtonClick = useCallback(() => { setVisible(true)}, [setVisible]);

	const handleConnectButtonClick = useCallback(() => {
		// NOTE: Connection promise acts weird and works only after second click hens adding this hack here.
		connect().then(() => connect());
	}, [connect]);

	const handleDisconnectButtonClick = useCallback(() => {
		disconnect();
	}, [disconnect]);

	const handleHideBalanceButtonClick = useCallback(() => {
		setIsBalanceHidden(isBalanceHidden => !isBalanceHidden);
	}, []);

	const handleHideCurrencyButtonClick = useCallback(() => {
		setIsCurrencyHidden(isCurrencyHidden => !isCurrencyHidden);
	}, []);

	const handleChangeAutoConnect = useCallback(() => {
		setAutoConnect((autoConnect ? false : true));
	})

	const onJoyrideClick = useCallback(() =>{
		setJoyride('startAgain')
	},
		[setJoyride]
	)

	const handleRedeemButtonClick = useCallback(async () => {
		const redeemableBlockchainMissions = redeemableMissions.map(({ bcMission }) => bcMission);

		if (!xAssociatedTokenAddress) {
			toast.error('You need an Investor Ata to do this transaction!');
			return;
		}

		try {
			setIsLoading(true);
			//const walletPublicKey = anchorWallet.publicKey.toString();
			//await axios.get(`${baseURL}/v1/dapp/rewards/${walletPublicKey}`);

			const reward = await getAllUserVestings({
				missionsWithClaims: redeemableBlockchainMissions,
				investorPub: anchorWallet.publicKey,
				associatedTokenAddress: xAssociatedTokenAddress,
				wallet: anchorWallet,
			});

			await axios.post(`${baseURL}/v1/dapp/rewards`, {
				mission_mint: 'n-a-multiple-missions',
				account_address: anchorWallet.publicKey.toString(),
				amount: reward,
				paid: true,
			});

			setShouldRefreshMissions(shouldRefreshMissions => !shouldRefreshMissions);

			toast.success('You have successfully retrieved your tokens!');
		} catch (error) {
			console.error(error);
			toast.error(`Error during redeeming: ${error.message}`);
		} finally {
			setIsLoading(false);
		}
	}, [redeemableMissions, xAssociatedTokenAddress, anchorWallet, setShouldRefreshMissions]);

	useOnClickOutside(asideRef, isWalletModalVisible ? () => undefined : onCloseButtonClick);

	return (
		<aside ref={asideRef} id="wallet" className="global">
			<button className="close-btn" onClick={onCloseButtonClick}>
				<div className="icon-cross btn-icon" />
			</button>
			<div className="settings-btn">
				<div className="icon-settings btn-icon" />
				<ul className="settings-subnav">
					<li>
						<button onClick={handleHideBalanceButtonClick}>
							<span>{isBalanceHidden ? 'Show Balance' : 'Hide Balance'}</span>{' '}
						</button>
					</li>
					<li>
						<button onClick={handleHideCurrencyButtonClick}>
							<span>{isCurrencyHidden ? 'Display Currency' : 'Hide Currency'}</span>
						</button>
					</li>
					<li>
						<button onClick={onShowAtaOptionsButtonClick}>
							<span>Show ATA Options</span>
						</button>
					</li>
					<li>
						<button onClick={handleChangeAutoConnect}>
							{autoConnect ? (
								<span>Auto-Connect: On</span>
							) : (
								<span>Auto-Connect: Off</span>		
							)}
						</button>
					</li>
				</ul>
			</div>
			<div className="wallet-inner">
				<div className="bg-glow">
					<Image src="/images/wallet-gradient@2x.jpg" className="spacer" alt="" />
				</div>
				<div className="section">
					<h2>Wallet</h2>
					{wallet ? (
						connected ? (
							<button className="connect-button cta-btn large hasIcon" onClick={handleDisconnectButtonClick}>
								<Image src="/images/icons/disconnect.svg" className="cta-icon" alt="Disconnect" />
								<span>{disconnecting ? 'Disconnecting ...' : 'Disconnect'}</span>
							</button>
						) : (
							<button className="connect-button cta-btn large hasIcon" onClick={handleConnectButtonClick}>
								<Image src="/images/icons/connect.svg" className="cta-icon" alt="Connect" />
								<span>{connecting ? 'Connecting ...' : 'Connect'}</span>
							</button>
						)
					) : (
						<button className="connect-button cta-btn large hasIcon" onClick={handleSelectWalletButtonClick}>
							<Image src="/images/icons/ghost.svg" className="cta-icon" alt="Select" />
							<span>Select Wallet</span>
						</button>
					)}
					<div className="wallet-group">
						<h4>ARCADE Balance</h4>
						<div className="row">
							{isLoadingAccountBalance ? (
								<img src="/images/icons/diamond-loader.svg" alt="Total" className="icon" />
							) : (
								<p className="lrg">{isBalanceHidden ? '****' : numberWithCommas(account.arcade)}</p>
							)}
							{!isCurrencyHidden && <span className="coin-icon arcade" title="Arcade" />}
						</div>
					</div>
					<div className="wallet-group">
						<h4>xARCADE Balance</h4>
						<div className="row">
							{isLoadingAccountBalance ? (
								<img src="/images/icons/diamond-loader.svg" alt="Total" className="icon" />
							) : (
								<p className="lrg">{isBalanceHidden ? '****' : numberWithCommas(account.xArcade)}</p>
							)}
							{!isCurrencyHidden && <span className="coin-icon x-arcade" title="xArcade" />}
						</div>
					</div>
					<div className="wallet-group">
						{/* NOTE: Commenting out this section according to this issue - https://github.com/ArcadeDevelopment/dapp-demo/issues/42 */}
						{/* <div className="row cta">
							<button
								className="hasArrow"
								onClick={() =>
									openBrowserPopupWindow({
										url: 'https://ftx.us/pay/request?address=RCade47ZKErNcQB1CgkpEZUEmyfsqi2qh21mSCWASgm&tag=&wallet=sol&memoIsRequired=false&memo=&allowTip=true',
										windowName: 'Buy Arcade',
										width: 500,
										height: 600,
									})
								}
							>
								<span className="icon-arrow btn-icon" />
								<span className="txt">Buy Arcade</span>
							</button>
						</div> */}
						{/*
						<br />
						<div className="row cta">
							{/* NOTE: Commenting out this section - https://github.com/ArcadeDevelopment/dapp-demo/issues/44
							<button className="hasArrow" onClick={onBridgeButtonClick}>
								<span className="icon-arrow btn-icon" />
								<span className="txt">Bridge</span>
							</button>*/}
							{/*
							<button className="hasArrow" onClick={onConvertTokensButtonClick}>
								<span className="icon-arrow btn-icon" />
								<span className="txt">Convert Tokens</span>
							</button>
						</div>
						*/ }
						<div className="row cta">
							<button className="hasArrow" onClick={onJoyrideClick}>
								<span className="icon-arrow btn-icon" />
								<span className="txt">Restart Tour</span>
							</button>
						</div>
					</div>
				</div>
				<div className="section">
					<h2>Rewards Center</h2>
					{/* <div className="wallet-group">
						<h4>Rewards Balance</h4>
						<div className="row">
							<p className="lrg">{isBalanceHidden ? '****' : 0}</p>
							{!isCurrencyHidden && <p className="sm">ARCADE</p>}
						</div>
					</div> */}
					<div className="wallet-group">
						<h4>Total Rewards Earned</h4>
						<div className="row">
							{isLoadingRewardBalance ? (
								<img src="/images/icons/diamond-loader.svg" alt="Total" className="icon" />
							) : (
								<p className="lrg">{isBalanceHidden ? '****' : reward.total}</p>
							)}
							{!isCurrencyHidden && <span className="coin-icon x-arcade" title="xArcade" />}
						</div>
					</div>
					<div className="wallet-group">
						<h4>Total Rewards Pending</h4>
						<div className="row">
							{isLoadingRewardBalance ? (
								<img src="/images/icons/diamond-loader.svg" alt="Total" className="icon" />
							) : (
								<p className="lrg">{isBalanceHidden ? '****' : reward.pending}</p>
							)}
							{!isCurrencyHidden && <span className="coin-icon x-arcade" title="xArcade" />}
						</div>
					</div>
					<div className="wallet-group">
						<h4>Reclaimable xArcade</h4>
						<div className="row">
							{isLoadingRewardBalance ? (
								<img src="/images/icons/diamond-loader.svg" alt="Total" className="icon" />
							) : (
								<p className="lrg">{isBalanceHidden ? '****' : reward.reclaimable}</p>
							)}
							{!isCurrencyHidden && <span className="coin-icon x-arcade" title="xArcade" />}
						</div>
					</div>
					{wallet && !isEmpty(missionPools) && (
						<div className="wallet-group cta">
							{isEmpty(redeemableMissions) ? (
								<Tippy content="There are currently no missions to redeem.">
									<div>
										<button className="cta-btn large" disabled>
											Redeem All Missions
										</button>
									</div>
								</Tippy>
							) : (
								<button className="cta-btn large" onClick={handleRedeemButtonClick}>
									{isLoading && <Loader />}
									<span>{isLoading ? 'Please wait...' : 'Redeem All Missions'}</span>
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		</aside>
	);
};
