import { Toaster } from 'react-hot-toast';
import { Suspense, useMemo } from 'react';
import { clusterApiUrl } from '@solana/web3.js';
import { MetaMaskProvider } from 'metamask-react';
import { HelmetProvider } from 'react-helmet-async';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

import { useLocalStorage } from 'usehooks-ts';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import {
	GlowWalletAdapter,
	PhantomWalletAdapter,
	SlopeWalletAdapter,
	SolflareWalletAdapter,
	SolletExtensionWalletAdapter,
	SolletWalletAdapter,
	TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';

import { Routes } from 'routes';
import { BalancesContextProvider } from 'contexts/Balances';
import { MissionsContextProvider } from 'contexts/Missions';

export const App = () => {
	// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
	const network = WalletAdapterNetwork.Devnet;

	// You can also provide a custom RPC endpoint.
	const endpoint = useMemo(() => clusterApiUrl(network), [network]);

	const wallets = useMemo(
		() => [
			// @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
			// Only the wallets you configure here will be compiled into your application, and only the dependencies
			// of wallets that your users connect to will be loaded.
			new GlowWalletAdapter(),
			new PhantomWalletAdapter(),
			new SlopeWalletAdapter(),
			new SolflareWalletAdapter({ network }),
			new SolletExtensionWalletAdapter(),
			new SolletWalletAdapter(),
			new TorusWalletAdapter(),
		],
		[network]
	);

	const [autoConnect, setAutoConnect] = useLocalStorage('autoconnect', false);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<MetaMaskProvider>
				<WalletProvider wallets={wallets} autoConnect={autoConnect}>
					<WalletModalProvider>
						<HelmetProvider>
							<Suspense fallback="loading">
								<MissionsContextProvider>
									<BalancesContextProvider>
										<Routes />
										<Toaster duration={5} position="top-right" containerStyle={{ zIndex: 200000 }} toastOptions={{ duration: 5000 }} />
									</BalancesContextProvider>
								</MissionsContextProvider>
							</Suspense>
						</HelmetProvider>
					</WalletModalProvider>
				</WalletProvider>
			</MetaMaskProvider>
		</ConnectionProvider>
	);
};
