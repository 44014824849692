import axios from 'axios';
import toast from 'react-hot-toast';
import { BN, web3 } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { baseURL } from 'constants';
import { macroToMicro } from 'utils';
import { solanaEnvNoSig, solanaEnvSig } from 'api/solana';
import { fusionLayerBlockchainDB } from 'api/fusionLayerBlockchainDB';

export const MAX_INVESTMENTS = 360;

export const fetchMissionPools = async () => {
	try {
		const { programNoSig, trustedOwnerPub, programId } = solanaEnvNoSig();

		const response = await axios.get(`${baseURL}/v1/dapp/missionpools/${programId}`);
		const allMissions = await getMissionsFromBlockchain(programNoSig);
		const fusedResData = fusionLayerBlockchainDB(response.data, allMissions);
		return fusedResData;
	} catch (error) {
		toast.error(`There was an error while fetching missions: ${error.message}`);
		console.error(error);
		throw error;
	}
};

export const requestMission = async ({
	description,
	endDate,
	expectedRewards,
	feePercent,
	gameType,
	maxPoolSize,
	maxVestingPercent,
	rewardCoinType,
	title,
	defaultImage,
	images,
	wallet,
	poolID,
	fundingEndDate
}) => {
	try {
		const { xMint } = solanaEnvSig(wallet);
		const response = await axios.post(`${baseURL}/v1/dapp/missionpools`, {
			gamePhase: 0,
			title,
			max_pool_size: maxPoolSize,
			duration: endDate,
			expected_rewards: expectedRewards,
			expected_rewards_coin: rewardCoinType,
			game: gameType,
			default_image: defaultImage,
			description,
			xMint: xMint,
			trustedOwnerPub: wallet.publicKey,
			// NOTE: Hardcoded sponsors.
			sponsors: ['62425bda59b8e0a16143e457', '62425c0759b8e0a16143e458'],
			images: images,
			feePercent: feePercent,
			maxVestingPercent: maxVestingPercent,
			poolID: poolID,
			wallet: wallet,
			fundingEndDate: fundingEndDate,
		});

		return { ...response.data };
	} catch (error) {
		console.error(error, 'There was an error while creating a mission.');
		throw error;
	}
};

export const createMission = async ({
	id,
	description,
	endDate,
	expectedRewards,
	feePercent,
	gameType,
	maxPoolSize,
	maxVestingPercent,
	rewardCoinType,
	title,
	defaultImage,
	images,
	wallet,
	poolID,
}) => {
	try {
		const { xMint } = solanaEnvSig(wallet);
		const blockchainMission = await createMissionInBlockchain({
			endDate,
			gameType,
			feePercent,
			maxVestingPercent,
			maxPoolSize,
			wallet,
		});

		const response = await axios.post(`${baseURL}/v1/dapp/missions/${id}`, {
			mission_mint: blockchainMission.base.toString(),
			title,
			max_pool_size: maxPoolSize,
			duration: endDate,
			expected_rewards: expectedRewards,
			expected_rewards_coin: rewardCoinType,
			game: gameType,
			default_image: defaultImage,
			description,
			xMint: xMint,
			trustedOwnerPub: wallet.publicKey,
			// NOTE: Hardcoded sponsors.
			sponsors: ['62425bda59b8e0a16143e457', '62425c0759b8e0a16143e458'],
			images: images,
			poolID: poolID,
			wallet: wallet.publicKey
		});

		return { ...response.data, bcMission: blockchainMission };
	} catch (error) {
		console.error(error, 'There was an error while creating a mission.');
		throw error;
	}
};

export async function getMissionsFromBlockchain(program, optionalUserPub) {
	const { trustedOwnerPub } = solanaEnvNoSig();
	let missions = [];
	let validTasks = [];

	const accountData = await program.account.data.all();
	const tasks = await program.account.mission.all();
	for (let i = 0; i < tasks.length; i++) {
		let trusted = trustedOwnerPub.filter(pubkey => tasks[i].account.owner);
		if (trusted) {
			validTasks.push(tasks[i]);
		}
	}

	for (let i = 0; i < validTasks.length; i++) {
		const dataInfo = accountData.find(e => validTasks[i].account.dataAccount.equals(e.publicKey));
		const investments = dataInfo.account.events;

		if (!optionalUserPub || hasUserInvestor(investments, optionalUserPub)) {
			let currentVesting = 0;
			for (let i = 0; i < investments.length; i++) {
				currentVesting += investments[i].amount.toNumber();
			}
			const [missionAta] = await web3.PublicKey.findProgramAddress([validTasks[i].publicKey.toBuffer()], program.programId);
			missions.push({
				base: validTasks[i].publicKey,
				data: validTasks[i].account.dataAccount,
				ata: missionAta,
				target: validTasks[i].account.poolTarget.toNumber(),
				currentVesting: currentVesting,
				bcGamePhase: validTasks[i].account.phase,
				bcEndDate: validTasks[i].account.endDate.toNumber(),
			});
		}
	}

	return missions;
}

export async function createMissionInBlockchain({
	//
	endDate,
	gameType,
	feePercent,
	maxVestingPercent,
	maxPoolSize,
	wallet,
}) {
	const { program, xMint, trustedOwnerPub } = solanaEnvSig(wallet);
	const endDateBn = new BN(endDate);
	const poolTarget = new BN(maxPoolSize);
	const missionBase = web3.Keypair.generate();
	const missionData = web3.Keypair.generate();
	const [missionAta, bump] = await web3.PublicKey.findProgramAddress([missionBase.publicKey.toBuffer()], program.programId);

	await program.rpc.createMission(bump, endDateBn, poolTarget, feePercent, maxVestingPercent, gameType, {
		accounts: {
			data: missionData.publicKey,
			mission: missionBase.publicKey,
			missionAta: missionAta,
			owner: wallet.publicKey,
			tokenType: xMint,
			tokenProgram: TOKEN_PROGRAM_ID,
			systemProgram: web3.SystemProgram.programId,
			rent: web3.SYSVAR_RENT_PUBKEY,
		},
		instructions: [await program.account.data.createInstruction(missionData, 8 + MAX_INVESTMENTS * 72)],
		signers: [missionData, missionBase],
	});
	
	return {
		base: missionBase.publicKey,
		data: missionData.publicKey,
		ata: missionAta,
		target: maxPoolSize,
		currentVesting: 0,
		bcGamePhase: 1,
		bcEndDate: endDate
	};
}

function calculateReclaimable(taskOnChain, dataOnChain, userPub) {
	// simulates the on-chain program reward calculation
	let vesting = 0;
	let reward = 0;
	let investments = dataOnChain.events;
	for (let i = 0; i < investments.length; i++) {
		let isAccount = userPub.equals(investments[i].investor);
		let isAmount = investments[i].amount.toNumber() !== 0;
		if (isAccount && isAmount) {
			vesting += investments[i].amount.toNumber();
			let shareFraction = investments[i].amount.toNumber() / taskOnChain.poolTarget.toNumber();
			reward += shareFraction * taskOnChain.reward.toNumber();
		}
	}

	return { vesting, reward };
}

export async function getReclaimableAmounts({ userBlockChainMissions, wallet, walletPublicKey }) {
	const userPub = walletPublicKey;
	const { program } = solanaEnvSig(wallet);

	let reclaimableAmounts = [];

	for (let i = 0; i < userBlockChainMissions.length; i++) {
		if(userBlockChainMissions[i]?.base !== undefined){
			const taskOnChain = await program.account.mission.fetch(userBlockChainMissions[i].base);
			const dataOnChain = await program.account.data.fetch(taskOnChain.dataAccount);
			reclaimableAmounts.push(calculateReclaimable(taskOnChain, dataOnChain, userPub));
		}
	}

	return reclaimableAmounts;
}

export async function getCompletedMissions({ wallet }) {
	const completed = await axios.get(`${baseURL}/v1/dapp/missions/completed/${wallet.publicKey.toString()}`);
	return completed
}

function hasUserInvestor(taskVestings, userPub) {
	for (let i = 0; i < taskVestings.length; i++) {
		let isAccount = userPub.equals(taskVestings[i].investor);
		let isAmount = taskVestings[i].amount.toNumber() !== 0;
		if (isAccount && isAmount) {
			return true;
		}
	}
	return false;
}

export async function getIsValidAmount({ amount, blockchainMission, investorPub, program }) {
	if (amount <= 0) {
		return false;
	}
	const missionOnChain = await program.account.mission.fetch(blockchainMission.base);
	const maxFraction = missionOnChain.maxVestingPercent / 100;
	
	const maxAmount = maxFraction * missionOnChain.poolTarget.toNumber();
	console.log(maxAmount)
	if (amount > maxAmount) {
		return false;
	}

	const dataOnChain = await program.account.data.fetch(blockchainMission.data);
	let investments = dataOnChain.events;

	let totalAmount = amount;
	for (let i = 0; i < investments.length; i++) {
		if (investorPub.equals(investments[i].investor)) {
			totalAmount += investments[i].amount.toNumber();
		}
	}

	return totalAmount <= maxAmount;
}

export async function getInvestedAmount({ missionPoolDetail, blockchainMissionData, wallet }) {
	try{
	const investorPub = wallet.publicKey;
	const { program } = solanaEnvSig(wallet);
		if (missionPoolDetail?.gamePhase !== 5) {
			const dataOnChain = await program.account.data.fetch(blockchainMissionData);
			const investments = dataOnChain.events;

			for (let i = 0; i < investments.length; i++) {
				const isInvestorAccount = investorPub.equals(investments[i].investor);
				const hasInvestedAmount = investments[i].amount.toNumber() !== 0;
				if (isInvestorAccount && hasInvestedAmount) {
					return macroToMicro(investments[i].amount.toNumber());
				}
			}
		}
		if (missionPoolDetail?.gamePhase === 0)
			return 0;
		const contributions = await axios.get(`${baseURL}/v1/dapp/missions/${missionPoolDetail?.bcMission.base.toString()}/contributions/${wallet.publicKey.toString()}`)
		return contributions.data;
	}catch(ex){
		console.error("Error while trying to getInvestedAmount with: ", blockchainMissionData.toString())
		console.error("Error follows: ", ex);
	}

	return 0;
}

export async function increaseTokens({ amount, blockchainMission, ownerAta, wallet }) {
	let transactionId;
	const investorPub = wallet.publicKey;
	const { program } = solanaEnvSig(wallet);

	const investorAtaKey = new web3.PublicKey(ownerAta);
	const dataOnChain = await program.account.data.fetch(blockchainMission.data);
	const investedAmount = await getInvestedAmount({ blockchainMissionData: blockchainMission.data, wallet }) + amount;
	const investments = dataOnChain.events;
	let indexes = [];
	for (let i = 0; i < investments.length; i++) {
		let isAccount = investorPub.equals(investments[i].investor);
		let isAmount = investments[i].amount.toNumber() !== 0;
		if (isAccount && isAmount) {
			indexes.push(i);
		}
	}
	if (indexes.length === 0) {
		throw new Error('No vesting found!');
	}
	for (let i = 0; i < indexes.length; i++) {
		let index = new BN(indexes[i]);
		transactionId = await program.rpc.withdrawVesting(index, {
			accounts: {
				data: blockchainMission.data,
				mission: blockchainMission.base,
				investor: investorPub,
				investorAta: investorAtaKey,
			},
			instructions: [
				await program.rpc.withdrawVesting(index, {
					accounts: {
						data: blockchainMission.data,
						mission: blockchainMission.base,
						investor: investorPub,
						investorAta: investorAtaKey,
					}
				}),
				await program.rpc.investTokens(new BN(investedAmount), {
					accounts: {
						data: blockchainMission.data,
						mission: blockchainMission.base,
						missionAta: blockchainMission.ata,
						investor: investorPub,
						investorAta: investorAtaKey,
						tokenProgram: TOKEN_PROGRAM_ID,
					},
				}),
			],
		});
	}
	return transactionId;
}

export async function investTokens({ amount, blockchainMission, ownerAta, wallet }) {
	const investorPub = wallet.publicKey;
	const { program } = solanaEnvSig(wallet);
	const investedAmount = await getInvestedAmount({ blockchainMissionData: blockchainMission.data, wallet });
	if (investedAmount > 0) {
		throw new Error('Only one investment allowed!');
	}
	const isValid = await getIsValidAmount({ amount, blockchainMission, investorPub, program });
	if (!isValid) {
		throw new Error('Amount not valid!');
	}
	const investorAtaKey = new web3.PublicKey(ownerAta);
	const bnAmount = new BN(amount);
	let transactionId
	try{
		transactionId = await program.rpc.investTokens(bnAmount, {
			accounts: {
				data: blockchainMission.data,
				mission: blockchainMission.base,
				missionAta: blockchainMission.ata,
				investor: investorPub,
				investorAta: investorAtaKey,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		});
		
		const metric = await axios.post(`${baseURL}/v1/dapp/missions/${blockchainMission.base.toString()}/deposit`, {
			amount: amount,
			user: investorPub,
			txid: transactionId,
		})
		return transactionId;
	}catch(ex){
		console.error(ex);
		if (transactionId)
			return transactionId;
		throw ex;
	}
}

export async function withdrawVesting({ blockchainMission, ownerAta, wallet }) {
	let transactionId = '';
	const investorPub = wallet.publicKey;
	const { program } = solanaEnvSig(wallet);

	const investorAtaKey = new web3.PublicKey(ownerAta);
	const dataOnChain = await program.account.data.fetch(blockchainMission.data);
	const investments = dataOnChain.events;
	let indexes = [];
	for (let i = 0; i < investments.length; i++) {
		let isAccount = investorPub.equals(investments[i].investor);
		let isAmount = investments[i].amount.toNumber() !== 0;
		if (isAccount && isAmount) {
			indexes.push(i);
		}
	}
	if (indexes.length === 0) {
		throw new Error('No vesting found!');
	}
	for (let i = 0; i < indexes.length; i++) {
		let index = new BN(indexes[i]);
		transactionId = await program.rpc.withdrawVesting(index, {
			accounts: {
				data: blockchainMission.data,
				mission: blockchainMission.base,
				missionAta: blockchainMission.ata,
				investor: investorPub,
				investorAta: investorAtaKey,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		});
	}

	return transactionId;
}

export async function endTask({ isSuccess, amount, blockchainMission, ownerAta, wallet }) {
	const { program } = solanaEnvSig(wallet);
	const ownerPub = wallet.publicKey;
	const ownerAtaKey = new web3.PublicKey(ownerAta);
	const returnAmount = new BN(amount);

	const ending = await program.rpc.endTask(returnAmount, isSuccess, {
		accounts: {
			mission: blockchainMission.base,
			missionAta: blockchainMission.ata,
			owner: ownerPub,
			ownerAta: ownerAtaKey,
			tokenProgram: TOKEN_PROGRAM_ID,
		},
	});
	if (ending) {
		const response = await axios.get(`${baseURL}/v1/dapp/missions/${blockchainMission.base.toString()}/close`)
		return { ...response };
	}
}

export async function getVesting({ mission, investorPub, investorAta, program }) {
	const investorAtaKey = new web3.PublicKey(investorAta);
	const dataOnChain = await program.account.data.fetch(mission.data);
	let investments = dataOnChain.events;
	let indexes = [];
	for (let i = 0; i < investments.length; i++) {
		let isAccount = investorPub.equals(investments[i].investor);
		let isAmount = investments[i].amount.toNumber() !== 0;
		if (isAccount && isAmount) {
			indexes.push(i);
		}
	}
	if (indexes.length === 0) {
		throw new Error('No vesting found!');
	}
	const taskOnChain = await program.account.mission.fetch(mission.base);
	const { reward } = calculateReclaimable(taskOnChain, dataOnChain, investorPub);
	for (let i = 0; i < indexes.length; i++) {
		let index = new BN(indexes[i]);
		await program.rpc.getVesting(index, {
			accounts: {
				data: mission.data,
				mission: mission.base,
				missionAta: mission.ata,
				investor: investorPub,
				investorAta: investorAtaKey,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		});
	}
	return reward;
}

export async function getAllVestings({ missionsWithClaims, wallet, associatedTokenAddress }) {
	const { program } = solanaEnvSig(wallet);
	let transactions = [];
	
	const investorAtaKey = new web3.PublicKey(associatedTokenAddress);
	const dataOnChain = await program.account.data.fetch(missionsWithClaims.data);
	let investments = dataOnChain.events;
	let indexes = [];
	let rewardTally = 0;
	for (let i = 0; i < investments.length; i++) {
		let isAmount = investments[i].amount.toNumber() !== 0;
		if (isAmount) {
			indexes.push(i);
		}
	}
	if (indexes.length === 0) {
		throw new Error(`No vesting found in mission!`);
		console.log(`No vestings found in mission!`);
	}
	
	const recentBlockhash = (await program.provider.connection.getRecentBlockhash()).blockhash;
	for (let i = 0; i < indexes.length; i++) {
		let index = new BN(indexes[i]);
		const trx = program.transaction.getVesting(index, {
			accounts: {
				data: missionsWithClaims?.data,
				mission: missionsWithClaims?.base,
				missionAta: missionsWithClaims?.ata,
				investor: wallet.publicKey,
				investorAta: investorAtaKey,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		});
		trx.feePayer = wallet.publicKey;
		trx.recentBlockhash = recentBlockhash;
		transactions.push(trx);
	}
	rewardTally += missionsWithClaims?.userReward;
	const signedTrxs = await program.provider.wallet.signAllTransactions(transactions);
	let trxIds = [];
	for (let i = 0; i < signedTrxs.length; i++) {
		const trxId = await program.provider.connection.sendRawTransaction(signedTrxs[i].serialize(),  {
			skipPreflight: false,
			preflightCommitment: "confirmed",
			maxRetries: 5,
		});
		trxIds.push(trxId);
	}
	for (let i = 0; i < trxIds.length; i++) {
		let result = await program.provider.connection.getSignatureStatus(trxIds[i], { searchTransactionHistory: true });
		if (result && result.value && result.value.err === null) {
			console.log(`Transaction ${i + 1} has succeeded.`);
		}
		else{
			throw new Error(`Transaction ${i + 1} has failed. However, all prior transactions have succeeded.`);
		}
	}
	return rewardTally;
}

export async function getAllUserVestings({ missionsWithClaims, investorPub, associatedTokenAddress, wallet }) {
	const { program } = solanaEnvSig(wallet);

	if (missionsWithClaims.length === 0) {
		throw new Error('No missions found!');
	}
	let rewardTally = 0;
	const investorAtaKey = new web3.PublicKey(associatedTokenAddress);
	let transactions = [];
	for (let n = 0; n < missionsWithClaims.length; n++) {
		const dataOnChain = await program.account.data.fetch(missionsWithClaims[n].data);
		let investments = dataOnChain.events;
		let indexes = [];
		for (let i = 0; i < investments.length; i++) {
			let isAccount = investorPub.equals(investments[i].investor);
			let isAmount = investments[i].amount.toNumber() !== 0;
			if (isAccount && isAmount) {
				indexes.push(i);
			}
		}
		if (indexes.length === 0) {
			//throw new Error(`No vesting found in mission ${n + 1}!`);
			console.log(`No vestings found in mission ${n + 1}!`);
			continue;
		}
		const recentBlockhash = (await program.provider.connection.getLatestBlockhash('confirmed')).blockhash;
		for (let i = 0; i < indexes.length; i++) {
			let index = new BN(indexes[i]);
			const trx = program.transaction.getVesting(index, {
				accounts: {
					data: missionsWithClaims[n].data,
					mission: missionsWithClaims[n].base,
					missionAta: missionsWithClaims[n].ata,
					investor: investorPub,
					investorAta: investorAtaKey,
					tokenProgram: TOKEN_PROGRAM_ID,
				},
			});
			trx.feePayer = investorPub;
			trx.recentBlockhash = recentBlockhash;
			transactions.push(trx);
		}
		rewardTally += missionsWithClaims[n].userReward;
	}
	const signedTrxs = await program.provider.wallet.signAllTransactions(transactions);
	let trxIds = [];
	for (let i = 0; i < signedTrxs.length; i++) {
		const trxId = await program.provider.connection.sendRawTransaction(signedTrxs[i].serialize());
		trxIds.push(trxId);
	}
	for (let i = 0; i < trxIds.length; i++) {
		let result = await program.provider.connection.confirmTransaction(trxIds[i]);
		if (result.value.err) {
			throw new Error(`Transaction ${i + 1} has failed. However, all prior transactions have succeeded.`);
		}
	}
	return rewardTally;
}

export async function returnVestings(mission, owner, program) {
	const dataOnChain = await program.account.data.fetch(mission.data);
	let investments = dataOnChain.events;
	for (let i = 0; i < investments.length; i++) {
		if (investments[i].amount.toNumber() === 0) {
			continue;
		}
		let index = new BN(i);
		await program.rpc.returnVesting(index, {
			accounts: {
				data: mission.data,
				mission: mission.base,
				missionAta: mission.ata,
				owner: owner.publicKey,
				investorAta: investments[i].investorAta,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
		});
	}
}

export async function closeMission({ mission, ownerAta, wallet }) {
	const { program } = solanaEnvSig(wallet);
	const ataKey = new web3.PublicKey(ownerAta);
	await program.rpc.closeMission({
		accounts: {
			data: mission.data,
			mission: mission.base,
			missionAta: mission.ata,
			owner: wallet.publicKey,
			ownerAta: ataKey,
			tokenProgram: TOKEN_PROGRAM_ID,
		},
	});

	await closeMissionFromDB(mission, wallet);

	return mission;
}

export async function closeMissionFromDB(mission, wallet){
	try{
		const response = await axios.delete(`${baseURL}/v1/dapp/missions/${mission.id}`);
		return { ...response.data };
	}catch (error) {
		console.error(error, 'There was an error while creating a mission.');
		throw error;
	}
}