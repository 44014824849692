import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { closeMission, closeMissionFromDB, returnVestings } from 'api/missions';
import { MissionsContext } from 'contexts/Missions';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

import { Loader } from 'components/Loader';
import { MissionCard } from 'components/MissionCard';
import { DefaultAtaInput } from 'components/DefaultAtaInput';
import { solanaEnvSig } from 'api/solana';

export const DeleteMissionModal = ({ onClose, missionCardProps }) => {
	const navigate = useNavigate();
	const wallet = useAnchorWallet();
	const [xAta] = useLocalStorage('xAta');
	const [ownerAta, setOwnerAta] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [shouldShowAtaOptions] = useLocalStorage('shouldShowAtaOptions');
	const { xAssociatedTokenAddress: defaultXAssociatedTokenAddress } = useAssociatedTokenAddress();
	const { setMissionPools, setShouldRefresh: setShouldRefreshMission } = useContext(MissionsContext);

	const xAssociatedTokenAddress = xAta ?? defaultXAssociatedTokenAddress;

	const handleInputChange = useCallback(value => {
		setOwnerAta(value);
	}, []);

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);
			try {
				if (wallet) {
					if(missionCardProps.gamePhase != 0){
						const { program, xMint, trustedOwnerPub } = solanaEnvSig(wallet);
						await returnVestings(missionCardProps, wallet, program)
						await closeMission({ mission: missionCardProps, ownerAta: ownerAta ?? xAssociatedTokenAddress, wallet });
					}else{
						await closeMissionFromDB(missionCardProps)
					}					
					setMissionPools(missionPools => missionPools.filter(mission => mission.id !== missionCardProps.id));
					setShouldRefreshMission(shouldRefresh => !shouldRefresh);
				}
				toast.success('Mission deleted successfully!');
			} catch (error) {
				toast.error(`Error during mission deletion: ${error.message}`);
				console.error(error);
			} finally {
				setIsLoading(false);
				onClose();
			}
		},
		[wallet, setMissionPools, missionCardProps, ownerAta, xAssociatedTokenAddress, onClose, setShouldRefreshMission]
	);

	return (
		<div id="delete-mission" className="overlay global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose} disabled={isLoading}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={() => navigate(-1)}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					<form id="delete-mission-form" className="row main spaced" onSubmit={handleSubmit}>
						<div className="col left">
							<div className="modal-title">
								<div className="row title">
									<h3>Delete</h3>
								</div>
							</div>
							{shouldShowAtaOptions && <DefaultAtaInput id="delete-mission-ata" onChange={handleInputChange} />}
							<button type="submit" className="cta-btn large delete-button" disabled={isLoading}>
								{isLoading && <Loader />}
								<span>{isLoading ? 'Please wait...' : 'Delete'}</span>
							</button>
						</div>
						<div className="col right">
							<MissionCard {...missionCardProps} hasEnterButton={false} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
