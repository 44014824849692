import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { Loader } from 'components/Loader';

import { microToMacro } from 'utils';
import { solanaEnvSig } from 'api/solana';
import { requestMission } from 'api/missions';
import { MissionsContext, prepareMission } from 'contexts/Missions';

const numberFields = ['max-pool-size', 'fee-percent', 'max-vesting-percent', 'expected-rewards'];
const defaultFormValues = {
	title: '',
	'game-type': '',
	'max-pool-size': '',
	'fee-percent': '0',
	'max-vesting-percent': '60',
	'end-date': '',
	'expected-rewards': '0',
	'reward-coin-type': 'xArcade',
	description: '',
};

export const RequestMissionModal = ({ isOpen, onClose }) => {
	const wallet = useAnchorWallet();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState(defaultFormValues);
	const { setMissionPools, setShouldRefresh: setShouldRefreshMission } = useContext(MissionsContext);

	const handleChange = useCallback(event => {
		setFormValues(formValues => ({
			...formValues,
			[event.target.name]: numberFields.includes(event.target.name)
				? // NOTE: This RegEx is for filtering out non numeric values.
				  event.target.value.replace(/\D/g, '')
				: event.target.value,
		}));
	}, []);

	// TODO: Offload this to the "Add Game" and MediaManager sprint
	function getDefaultMissionImages(game) {
		let imgs = []
		switch (game) {
			case 'Star Atlas':
				imgs = ['/images/staratlas/cover.webp','/images/staratlas/cover2.webp','/images/staratlas/cover3.webp']
				return imgs[Math.floor(Math.random() * imgs.length)];
			case 'Illuvium':
				imgs = ['/images/illuvium/cover.webp','/images/illuvium/cover2.webp','/images/illuvium/cover3.webp']
				return imgs[Math.floor(Math.random() * imgs.length)];
			case 'Mavia':
				imgs = ['/images/heroes/cover.png','/images/heroes/cover2.png','/images/heroes/cover3.png','/images/heroes/cover4.png']
				return imgs[Math.floor(Math.random() * imgs.length)];
			default:
				return '/images/logo-staratlas@2x.png';
		}
	}

	function getMissionImages(game, howmany = 0){
		let imgs = [];
		switch(game){
			case 'Star Atlas':
				imgs = ['/images/staratlas/1MfLvG1ap6baeo-xCrrX.webp', '/images/staratlas/Calico_Compakt_Hero_Marketplace_Thumbnail.webp', '/images/staratlas/Calico_Emergenc_Ship_KeyVis_Rescue_Mission.webp', '/images/staratlas/Calico_Guardian_Marketplace_Thumbnail.webp'];
				return resolveImages(imgs, howmany);
			case 'Illuvium':
				imgs = ['/images/illuvium/ilv.webp','/images/illuvium/ilv.webp','/images/illuvium/ilv2.webp','/images/illuvium/ilv3.webp','/images/illuvium/ilv4.webp','/images/illuvium/ilv5.webp','/images/illuvium/ilv6.webp','/images/illuvium/ilv7.webp','/images/illuvium/ilv8.webp']
				return resolveImages(imgs, howmany);
			case 'Mavia':
				imgs = ['/images/heroes/hero_main_new.png','/images/heroes/herosimg1.jpg','/images/heroes/heroesimg3.png', '/images/heroes/heroesimg2.jpg']
			default:
				return [];
		}
	}

	function resolveImages(arr, count){
		if(count == 0)
			count = arr.length;
		
		if(count > arr.length)
			count = arr.length;
		
		let res = [];		
		for(let i = 0; i < count; i++){
			const ind = Math.floor(Math.random() * arr.length);
			res.push(arr[ind])
			arr.splice(ind, 1);
		}
		console.log(res);
		return res;
	}

	const handleSubmit = useCallback(
		async event => {
			event.preventDefault();
			setIsLoading(true);

			try {
				const { programId } = solanaEnvSig();
				console.log({
					defaultImage: getDefaultMissionImages(formValues['game-type']),
					game: formValues.game,
					gameType: formValues['game-type'],
					images: getMissionImages(formValues['game-type'], 3)
				})
				const mission = await requestMission({
					description: formValues.description,
					endDate: Date.parse(formValues['end-date']) / 1000,
					fundingEndDate: Date.parse(formValues['funding-end-date']) / 1000,
					expectedRewards: microToMacro(parseInt(formValues['expected-rewards'])),
					feePercent: formValues['fee-percent'],
					gameType: formValues['game-type'],
					maxPoolSize: microToMacro(parseInt(formValues['max-pool-size'])),
					maxVestingPercent: formValues['max-vesting-percent'],
					rewardCoinType: 'xArcade',
					title: formValues.title,
					defaultImage: getDefaultMissionImages(formValues['game-type']),
					game: formValues.game,
					images: getMissionImages(formValues['game-type'], 3),
					wallet: wallet.publicKey.toString(),
					poolID: programId
				});

				setMissionPools(missionPools => [prepareMission(mission), ...missionPools]);
				setShouldRefreshMission(shouldRefresh => !shouldRefresh);

				toast.success(`Mission "${mission.title}" created successfully!`);
			} catch (error) {
				toast.error(`Error during mission creation: ${error.message}`);
				console.error(error);
			} finally {
				setIsLoading(false);
				setFormValues(defaultFormValues);
				onClose();
			}
		},
		[formValues, onClose, setMissionPools, setShouldRefreshMission, wallet]
	);

	useEffect(() => {
		if (!isOpen) {
			setFormValues(defaultFormValues);
		}
	}, [isOpen]);

	return (
		<div id="request-mission" className="overlay global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose} disabled={isLoading}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={() => navigate(-1)}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					{/* FORM */}
					<form id="custom-form create-mission-form" className="row main spaced" onSubmit={handleSubmit}>
						<div className="modal-title">
							<div className="row title">
								<h3>Create Mission</h3>
							</div>
						</div>
						<div className="form-field">
							<label htmlFor="title">Mission Title</label>
							<input
								type="text"
								name="title"
								id="title"
								placeholder="Enter here"
								required
								onChange={handleChange}
								value={formValues.title}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="game-type">Game Type</label>
							<select className="form-select" required onChange={handleChange} defaultValue="0" name="game-type" id="game-type">
								<option>Select a Game</option>
								<option value="Star Atlas">Star Atlas</option>
								<option value="Illuvium">Illuvium</option>
								<option value="Mavia">Heroes of Mavia</option>
							</select>
						</div>
						<div className="form-field">
							<label htmlFor="max-pool-size">Max Pool Size</label>
							<input
								type="text"
								name="max-pool-size"
								id="max-pool-size"
								placeholder="0"
								pattern="^[1-9][0-9]*$"
								required
								onChange={handleChange}
								value={formValues['max-pool-size']}
							/>
						</div>
						<div className="form-field fee-percent">
							<label htmlFor="fee-percent">Fee Percent</label>
							<input
								type="text"
								name="fee-percent"
								id="fee-percent"
								placeholder="0"
								required
								onChange={handleChange}
								value={formValues['fee-percent']}
							/>
						</div>
						<div className="form-field max-vesting-percent">
							<label htmlFor="max-vesting-percent">Max Vesting Percent</label>
							<input
								type="text"
								name="max-vesting-percent"
								id="max-vesting-percent"
								placeholder="0"
								required
								onChange={handleChange}
								value={formValues['max-vesting-percent']}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="end-date">Mission's End Date</label>
							<input
								type="datetime-local"
								name="end-date"
								id="end-date"
								placeholder="Select the end date"
								required
								onChange={handleChange}
								value={formValues['end-date']}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="funding-end-date">Funding End Date</label>
							<input
								type="datetime-local"
								name="funding-end-date"
								id="funding-end-date"
								placeholder="Select the funding end date"
								required
								onChange={handleChange}
								value={formValues['funding-end-date']}
							/>
						</div>
						<div className="form-field">
							<label htmlFor="expected-rewards">Expected Rewards (in xArcade)</label>
							<input
								type="text"
								name="expected-rewards"
								id="expected-rewards"
								placeholder="0"
								required
								onChange={handleChange}
								value={formValues['expected-rewards']}
							/>
						</div>
						{/*<div className="form-field">
							<label htmlFor="reward-coin-type">Reward Coin Type</label>
							<input
								type="text"
								name="reward-coin-type"
								id="reward-coin-type"
								placeholder="Enter here"
								required
								onChange={handleChange}
								value={formValues['reward-coin-type']}
							/>
						</div>*/}
						<div className="form-field textarea">
							<label htmlFor="description">Mission Description</label>
							<textarea
								name="description"
								id="description"
								placeholder="Enter here"
								required
								onChange={handleChange}
								value={formValues.description}
							/>
						</div>
						<button type="submit" className="cta-btn large create-mission-button" disabled={isLoading}>
							{isLoading && <Loader />}
							<span>{isLoading ? 'Please wait...' : 'Submit'}</span>
						</button>
					</form>
					{/* END FORM */}
				</div>
			</div>
		</div>
	);
};
