import { gsap } from 'gsap';
import { useLockedBody } from 'usehooks-ts';
import { Link, NavLink } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { memo, useCallback, useState } from 'react';

import { Image } from 'components/Image';

import { getNavigationItems } from 'constants';

export const PureMobileMenu = () => {
	const { connected: isConnected } = useWallet();
	const [isGlobalMenuOpen, setIsGlobalMenuOpen] = useState(false);

	const handleClick = useCallback(() => {
		if (!isGlobalMenuOpen) {
			setIsGlobalMenuOpen(true);
			gsap.to('.menu-wrap', { duration: 0.75, opacity: 1, display: 'block' });
		} else {
			setIsGlobalMenuOpen(false);
		}
	}, [isGlobalMenuOpen]);

	useLockedBody(isGlobalMenuOpen, 'root');

	return (
		<div id="globalMenu" className={`global ${isGlobalMenuOpen ? 'open expanded' : ''}`}>
			<button className="menu-btn" style={{ top: '10px' }} onClick={handleClick}>
				<div className="menu-line top">
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
				</div>
				<div className="menu-line bot">
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
					<div className="pixel" />
				</div>
				{/*         <div className="menu-line bot"><div className="icon-line"></div></div> */}
			</button>
			<Link to="/wallet-center">
				<button className="wallet-btn" style={{ top: '10px' }} title="Wallet">
					<div className="wallet-icon">
						<div className="notch" />
					</div>
				</button>
			</Link>
			<div className="menu-wrap">
				<div id="menu-scroll" className="menu-scroller">
					<div className="menu-contents">
						<div className="bg-glow">
							<Image src="/images/bg-gradient@2x.webp" className="spacer" alt="" />
						</div>
						<div className="logo">
							<Image src="/images/logo@2x.png" className="spacer" alt="Arcade" />
						</div>
						<nav className="main">
							<ul>
								{[{ title: 'Wallet Center', href: '/wallet-center' }, ...getNavigationItems(isConnected)].map(({ title, href }) => (
									<li key={title}>
										<NavLink to={href} end className={({ isActive }) => (isActive ? 'on' : undefined)}>
											{title}
										</NavLink>
									</li>
								))}
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export const MobileMenu = memo(PureMobileMenu);
