import { PublicKey } from '@solana/web3.js';
import { NavLink, Link } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { memo, useContext, useMemo, useCallback } from 'react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

import { Image } from 'components/Image';

import { BalancesContext } from 'contexts/Balances';
import { adminWalletPublicAddress, getNavigationItems } from 'constants';
import { numberWithCommas } from 'utils'

const PureHeader = ({ onWalletButtonClick }) => {
	const { publicKey } = useWallet();
	const { setVisible } = useWalletModal();
	const { wallet, connect, connecting, connected: isConnected } = useWallet();
	const { account, isLoadingAccountBalance } = useContext(BalancesContext);

	const connectedWalletPublicAddress = useMemo(() => publicKey && new PublicKey(publicKey).toString(), [publicKey]);

	const handleSelectWalletButtonClick = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const handleConnectButtonClick = useCallback(() => {
		// NOTE: Connection promise acts weird and works only after second click hens adding this hack here.
		connect().then(() => connect());
	}, [connect]);

	return (
		<header className="header global">
			<div className="contentContainer">
				<Link to="/" title="Link to homepage" className="logo">
					<Image src="/images/logo@2x.png" className="spacer" alt="Arcade Logo" />
				</Link>
				<nav className="main">
					<ul>
						{getNavigationItems(isConnected).map(({ title, href }) => (
							<li key={title}>
								<NavLink to={href} end>
									{title}
								</NavLink>
							</li>
						))}
						{adminWalletPublicAddress.includes(connectedWalletPublicAddress) && (
							<li>
								<NavLink to="/admin" end>
									Admin
								</NavLink>
							</li>
						)}
						<li>
							<a href="https://arcade2earn.io" className={'arcade'}>
								Arcade Home
							</a>
						</li>
					</ul>
				</nav>
				<div className="db-balance">
					{wallet ? (
						isConnected ? (
							publicKey && (
								<p>
									<span className="light">Balance</span>
									{isLoadingAccountBalance ? (
										'Loading…'
									) : (
										<>	
											<span title={numberWithCommas(account?.arcadeActual).toString() + ' Arcade'}>
												{numberWithCommas(account?.arcade)}
											</span>
											<span className="coin-icon arcade" title="Arcade" />
											<span> | </span>
											<span title={numberWithCommas(account?.xArcadeActual).toString() + ' xArcade'}>
												{numberWithCommas(account?.xArcade)}
											</span>
											<span className="coin-icon x-arcade" title="xArcade" />
												
										</>
									)}
								</p>
							)
						) : (
							<button className="connect-button cta-btn connect hasIcon" onClick={handleConnectButtonClick}>
								<span>{connecting ? 'Connecting…' : 'Connect'}</span>
							</button>
						)
					) : (
						<button className="connect-button cta-btn connect hasIcon" onClick={handleSelectWalletButtonClick}>
							<Image src="/images/icons/wallet-full.svg" className="cta-icon" alt="Wallet" />
							<span>Connect</span>
						</button>
					)}
					<button className="wallet-btn" onClick={onWalletButtonClick} title="Wallet">
						<div className="wallet-icon">
							<div className="notch" />
						</div>
					</button>
				</div>
			</div>
		</header>
	);
};

export const Header = memo(PureHeader);
