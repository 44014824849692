import dayjs from 'dayjs';

const thousand = 10 ** 3;
const billion = 10 ** 9;

export const microToMacro = value => Math.floor(parseFloat(value) * billion);
export const macroToMicro = value => parseFloat(parseFloat((value / billion) * thousand).toPrecision(12)) / thousand;

export const openBrowserPopupWindow = ({ url, windowName, width, height }) => {
	const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
	const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

	return window.open(
		url,
		windowName,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
	);
};

export const getDaysAndHoursFromEndDateDiff = endDate => {
	let diffInHours = dayjs.unix(endDate).diff(dayjs(new Date()), 'hours');
	let diffInMins = dayjs.unix(endDate).diff(dayjs(new Date()), 'minutes');
	const days = Math.floor(diffInHours / 24);
	const hours = diffInHours - days * 24;
	const mins = diffInMins - hours * 60;

	// If 1 day or greater, show Days and Hours, if less than a day show Hours and Mins, if less than an hour show Mins
	return days > 0
		? `Ends in ${days} days ` //${hours} hours
		: diffInHours > 0
		? `Ends in ${hours} hours ` //${mins} minutes
		: diffInMins > 0
		? `${mins} minutes`
		: `Ended on ${dayjs.unix(endDate).format('MMM D, YYYY')}`;
};

export const isMissionDurationOngoing = endDate => {
	let diffInHours = dayjs.unix(endDate).diff(dayjs(new Date()), 'hours');
	let diffInMins = dayjs.unix(endDate).diff(dayjs(new Date()), 'minutes');
	const days = Math.floor(diffInHours / 24);
	const hours = diffInHours - days * 24;
	const mins = diffInMins - hours * 60;

	// If 1 day or greater, show Days and Hours, if less than a day show Hours and Mins, if less than an hour show Mins
	return days > 0
		? true
		: diffInHours > 0
		? true
		: diffInMins > 0
		? true
		: false;
};

export const getGamePhaseTextFromBC = (gamePhase, endDate, funded) => {
	switch (gamePhase) {
		case 1:
			if(endDate < (Date.now() / 1000)){
				if(funded)
					return 'Expired - Withdraw your funds'
				else
					return 'Expired - Pool wasn`t fully funded';
			}else
				return 'Active';
		case 2:
			if(endDate < (Date.now() / 1000))
				if(funded)
					return 'Mission Finished - Awaiting payout';
				else
					return 'Mission Finished - Closing soon'
			else
				return 'Mission Ongoing';
		case 3:
			return 'Closed';
		default:
			return 'Unknown Phase';
	}
};

export const currencyFormatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

/*
https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
*/
export const numberWithCommas = (x) => {
	if (isNaN(x))
		return x;
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}