const mockInfo = {
	_id: '620ff97bbb3ebe4b8678b7d8',
	mission_mint: 'tMP9yZz1LupdT1RB2iNjyh1aMotrsp3QPVMiuobhSMQ',
	title: '!MongoDB ERROR!',
	max_pool_size: -1,
	duration: -1,
	expected_rewards: -1,
	expected_rewards_coin: 'ATLAS',
	game: 'Star_Atlas',
	default_image: 'staratlas/ship3.png',
	description:
		'Support a troop of 500 galactic crew members as they navigate one of the most formidable ships in the galaxy. The Pearce C11 is the most significant fleet carrier vessel to come from the MUD Territory. The C11’s hull is riddled with hangar bays and studded with turret weapon arrays providing a platform to house and protect an entire fleet of ships all the way up to capital sized docking mechanics. Attempting to approach this ship with hostility is a well documented fatal error and that fact is common knowledge for any Jorvik roving band of pirates. Often doubling as organization headquarters, the C11 provides every imaginable onboard accommodations to equip and sustain an entire fleet of ships and a broad roster of crew members.',
	sponsors: [
		{
			_id: '62425bda59b8e0a16143e457',
			company_name: 'CPU Maxxx',
			company_link: '',
			company_logo: '/assets/images/sponsors/cpumaxxx.png',
		},
		{
			_id: '62425c0759b8e0a16143e458',
			company_name: 'Decentralized Code',
			company_link: '',
			company_logo: '/assets/images/sponsors/decentralizedcode.png',
		},
	],
	images: ['staratlas/c11image1.jpg', 'staratlas/c11image2.jpg', 'staratlas/c11image3.jpg'],
};

export const fusionLayerBlockchainDB = (dbMissions, bcMissions) => {
	let fusedResData = [];

	bcMissions.forEach(bcMission => {
		let dbMission = dbMissions.find(item => bcMission.base.toString() === item.mission_mint);
		if (!dbMission) {
			console.error('Blockchain mission could not find database match! %s', bcMission.base.toString(), bcMission);
			dbMission = Object.assign({}, mockInfo);
		}
		dbMission.mission_mint = bcMission.base.toString();
		dbMission.max_pool_size = bcMission.target;
		dbMission.bcMission = bcMission;
		dbMission.bcGamePhase = bcMission.bcGamePhase;
		fusedResData.push(dbMission);
	});

	dbMissions.forEach(dbMission => {
		if(dbMission.gamePhase <= 1 && fusedResData.findIndex(e => e._id == dbMission._id) === -1){
			fusedResData.push(dbMission);
		}
	})

	return fusedResData;
};
