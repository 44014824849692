import { gsap } from 'gsap';
import toast from 'react-hot-toast';
import { useLocalStorage } from 'usehooks-ts';
import { useCallback, useContext, useState } from 'react';
import { useAnchorWallet } from '@solana/wallet-adapter-react';

import { microToMacro } from 'utils';
import { MissionsContext } from 'contexts/Missions';
import { BalancesContext } from 'contexts/Balances';
import { investTokens, withdrawVesting } from 'api/missions';
import { useAssociatedTokenAddress } from 'hooks/useAssociatedTokenAddress';

import { Loader } from 'components/Loader';
import { MissionCard } from 'components/MissionCard';
import { DefaultAtaInput } from 'components/DefaultAtaInput';

export const DepositUnstakeModal = ({ hasInvested, investedAmount, setInvestedAmount, onClose, missionCardProps, isFunding }) => {
	const wallet = useAnchorWallet();
	const [xAta] = useLocalStorage('xAta');
	const [ownerAta, setOwnerAta] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [disputeAmount, setDisputeAmount] = useState('');
	const [transactionId, setTransactionId] = useState('');
	const [shouldShowAtaOptions] = useLocalStorage('shouldShowAtaOptions');
	const { account, setShouldRefresh: setShouldRefreshBalance } = useContext(BalancesContext);
	const { xAssociatedTokenAddress: defaultXAssociatedTokenAddress } = useAssociatedTokenAddress();
	const xAssociatedTokenAddress = xAta ?? defaultXAssociatedTokenAddress;

	const { setMissionPools, setShouldRefresh: setShouldRefreshMission } = useContext(MissionsContext);

	const handleDisputeAmountInputChange = useCallback(event => {
		// eslint-disable-next-line no-useless-escape
		let value = event.target.value.replace(/[^0-9\.]/g, '');

		if (value.split('.').length > 2) {
			value = value.replace(/\.+$/, '');
		}

		setDisputeAmount(value);
	}, []);

	const handleDefaultAtaInputChange = useCallback(value => {
		setOwnerAta(value);
	}, []);

	const handleUnstakeButtonClick = useCallback(async () => {
		setIsLoading(true);

		try {
			if (wallet) {
				const transactionId = await withdrawVesting({
					blockchainMission: missionCardProps.bcMission,
					ownerAta: ownerAta ?? xAssociatedTokenAddress,
					wallet,
				});

				setTransactionId(transactionId);
				setMissionPools(missionPools =>
					missionPools.map(mission => {
						if (mission.id === missionCardProps.id) {
							return { ...mission, currentVesting: 0 };
						}

						return mission;
					})
				);

				setShouldRefreshMission(shouldRefresh => !shouldRefresh);
				setShouldRefreshBalance(shouldRefresh => !shouldRefresh);
				toast.success('Deposit was withdrawn successfully!');
				setInvestedAmount(0);
			}
		} catch (error) {
			toast.error(`Error during unstaking: ${error.message}`);
			console.error(error);
		} finally {
			setIsLoading(false);
			onClose();
			setDisputeAmount('');
		}
	}, [
		wallet,
		setInvestedAmount,
		missionCardProps,
		ownerAta,
		xAssociatedTokenAddress,
		setShouldRefreshMission,
		setShouldRefreshBalance,
		setMissionPools,
		onClose,
	]);

	const handleClickOnBalance = useCallback(async () => {
		try {
				if (account.xArcadeActual > ((missionCardProps.maxVestingPercent / 100) * missionCardProps.max_pool_size)) {
					if (account.xArcadeActual > (missionCardProps.max_pool_size - missionCardProps.currentVesting)) {
						setDisputeAmount((missionCardProps.max_pool_size - missionCardProps.currentVesting).toString());
					}
				} else if (account.xArcadeActual > (missionCardProps.max_pool_size - missionCardProps.currentVesting)) {
					setDisputeAmount((missionCardProps.max_pool_size - missionCardProps.currentVesting).toString());
				} else if (account.xArcadeActual === 0) {
					setDisputeAmount('0');
				} else {
					setDisputeAmount(account.xArcadeActual.toString());
				}
		} catch (ex) {
			return
		}
	})


	const handleDepositButtonClick = useCallback(async () => {
		setIsLoading(true);
		if (disputeAmount <= 0) {
			toast.error('The investment amount must be greater than zero!');
			setIsLoading(false);

			return;
		}

		try {
			if (wallet) {
				const transactionId = await investTokens({
					amount: microToMacro(parseFloat(disputeAmount)),
					blockchainMission: missionCardProps.bcMission,
					ownerAta: ownerAta ?? xAssociatedTokenAddress,
					wallet,
				});

				setTransactionId(transactionId);
			}

			toast.success(`You have successfully deposited ${disputeAmount} xArcade!`);

			setMissionPools(missionPools =>
				missionPools.map(mission => {
					if (mission.id === missionCardProps.id) {
						return { ...mission, currentVesting: disputeAmount, gamePhase: missionCardProps.poolSize === disputeAmount ? 2 : 1 };
					}

					return mission;
				})
			);

			setShouldRefreshMission(shouldRefresh => !shouldRefresh);
			setShouldRefreshBalance(shouldRefresh => !shouldRefresh);

			setInvestedAmount(disputeAmount);

			gsap.to('.modal-success-view', { startAt: { opacity: 0, display: 'block' }, duration: 1, opacity: 1 });
		} catch (error) {
			toast.error(`Error during depositing: ${error.message}`);
			console.error(error);
		} finally {
			setIsLoading(false);
			onClose();
		}
	}, [
		disputeAmount,
		wallet,
		setMissionPools,
		setShouldRefreshMission,
		setShouldRefreshBalance,
		setInvestedAmount,
		missionCardProps,
		ownerAta,
		xAssociatedTokenAddress,
		onClose,
	]);

	return (
		<div id="deposit" className="overlay deposit global">
			<div className="blanket" />
			<div className="overlay-wrap">
				<div className="overlay-modal module">
					<button className="close-btn" onClick={onClose} disabled={isLoading}>
						<span className="icon-cross btn-icon" />
					</button>
					{/* back button for mobile */}
					<button className="back-btn overlay-back hasArrow mob" onClick={onClose}>
						<span className="icon-arrow btn-icon" />
						<span className="txt">Back</span>
					</button>
					{/* FORM */}
					<div id="deposit-form" className="row main spaced">
						<div className="col left">
							{hasInvested ? (
								<div className="modal-title">
									<div className="row title">
										<h3>Withdraw</h3>
										<p>
											<span className="mobile-break">Contributed amount: </span>
											{investedAmount} xArcade &nbsp; | &nbsp;
											<span className="mobile-break">Balance:</span> {account.xArcade} xArcade
										</p>
									</div>
									{/* NOTE: Hiding this section because it was not implemented yet on BE */}
									{/* <div className="row field">
									<div className="col left">
										<label htmlFor="unstake-amount">Amount to Withdraw</label>
										<input id="unstake-amount" type="text" name="amount" placeholder={0.0} />
									</div>
									<div className="col right">
										<p>xArcade</p>
									</div>
								</div> */}
									{shouldShowAtaOptions && <DefaultAtaInput onChange={handleDefaultAtaInputChange} />}
									<div className="row cta">
										<button className="cta-btn large submit" onClick={handleUnstakeButtonClick} disabled={isLoading}>
											{isLoading && <Loader />}
											<span>{isLoading ? 'Please wait...' : 'Withdraw All'}</span>
										</button>
									</div>
								</div>
							) : (
								<div className="modal-title">
									<div className="row title">
										<h3>Deposit</h3>
											<p><button style={{color: 'inherit'}} onClick={handleClickOnBalance} onKeyDown={handleClickOnBalance}>
											<span className="mobile-break">Balance:</span> {account.xArcade} xArcade
											</button></p>
									</div>
									<div className="row field">
										<div className="col left">
											<label htmlFor="deposit-amount">Amount to Deposit</label>
											<input
												id="deposit-amount"
												type="text"
												name="amount"
												placeholder="0"
												value={disputeAmount}
												onChange={handleDisputeAmountInputChange}
											/>
										</div>
										<div className="col right">
											<p>xArcade</p>
										</div>
									</div>
										{shouldShowAtaOptions && <DefaultAtaInput onChange={handleDefaultAtaInputChange} />}
										{/*<p>Max Allowed Per MPC: {missionCardProps.maxVestingPercent}</p>*/}
									<div className="row cta">
										<button className="cta-btn large submit" onClick={handleDepositButtonClick} disabled={isLoading}>
											{isLoading && <Loader />}
											<span>{isLoading ? 'Please wait...' : 'Deposit'}</span>
										</button>
									</div>
								</div>
							)}
						</div>
						<div className="col right">
							<MissionCard {...missionCardProps} hasEnterButton={false} />
						</div>
					</div>
					{/* END FORM */}
					{/* THANK YOU */}
					<div className="modal-success-view">
						<div className="row thank-you spaced">
							<div className="bg-video">
								<video id="bgvid" autoPlay muted loop playsInline>
									<source src="/video/coins-hand.mp4" type="video/mp4" />
								</video>
							</div>
							<div className="row spaced">
								<div className="col left" />
								<div className="col right">
									<div className="row title">
										<h3>Success</h3>
									</div>
									<h3>{disputeAmount} xArcade deposited</h3>
									<p className="allow-break">Transaction: {transactionId}</p>
								</div>
							</div>
						</div>
					</div>
					{/* END THANK YOU */}
				</div>
			</div>
		</div>
	);
};
